import { StyleSheet, Platform } from 'react-native';
import { colors } from '_theme';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: Platform.OS === 'web' ? '100vh' : '100%',
    padding: 0,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
  },
  profileContainer: {
    backgroundColor: 'white',
    paddingBottom: 35,
    shadowColor: colors.black_swood,
    shadowOffset: { width: 1, height: 6 },
    shadowOpacity: 0.5,
    shadowRadius: 8,
  },
  list: {
    //backgroundColor: 'cyan',
  },
  dummyText: {
    color: 'black',
    fontSize: 50,
    fontFamily: 'VolteRounded-Semibold',
  },
});

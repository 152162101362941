import React, { useEffect } from 'react';
import { Pressable } from 'react-native';
import { CloseButton } from '_components';

export const screenOptions = {
    "Dashboard": {},
    "Test": {},
    "List": {},
    "Team": {
        title: 'Mon équipe'
    },
    "Review": {},
    "Feedbacks": {
        title: 'Mes évaluations'
    },
    "MonProfile": {
        title: 'Mon profil',
    },
    "Password": {},
    "Swoody": {
    },
    "Login": {
        headerShown: false
    },
    "reset": {
        headerTransparent: true,
    },
    "evaluation": ({ navigation, route }) => ({
        headerLeft: null,
        noShadow: true,
        smallHeader: true,
        headerRight: () => (
            <Pressable onPress={navigation.goBack}>
                <CloseButton />
            </Pressable>
        )
    }),
    "ThankYou": {
        headerShown: false
    }
}
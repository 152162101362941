import React from 'react';
import { View, StatusBar } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import { SVGLocal } from '_components'
import { styles } from './styles'
import { gradients } from '_theme'

export const LoadingFull = ({ style }) => {
    return (
        <View style={[styles.container, style]}>
            <StatusBar backgroundColor="transparent" translucent barStyle="light-content" />
            <LinearGradient
                {...{ ...gradients.identityDiagonal }}
                style={styles.linearGradient}
            >
                <View style={styles.logo}>
                    <SVGLocal name="boomerang_white" />
                </View>
            </LinearGradient>
        </View>
    );
}
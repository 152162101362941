import React from 'react';
import { StyleSheet, View } from 'react-native';

import { SVGLocal } from '_components/SVGLocal';

export const CloseButton = () => {
    return (
        <View style={styles.close} >
            <SVGLocal name={'close'} />
        </View>
    );
}

const styles = StyleSheet.create({
    close: {
        width: 20,
        height: 20,
    },
});
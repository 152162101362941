const INITIAL_STATE = {
  user: {},
  checklist: {
    todo: 0,
    tovalidate: 0,
  },
};

export const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_USER':
      var user = action.data.user;
      user.token = action.data.token;
      return { ...state, user };
    case 'SET_CHECKLIST':
      return { ...state, checklist: action.data };
    case 'REMOVE_TODO':
      var newTodo = state.checklist.todo;
      if (
        state.checklist &&
        state.checklist.todo &&
        Number.isInteger(state.checklist.todo) &&
        state.checklist.todo > 0
      ) {
        newTodo = state.checklist.todo - 1;
      }
      return {
        ...state, checklist: {
          ...state.checklist,
          todo: newTodo
        }
      };
    case 'REMOVE_TOVALIDATE':
      var newTovalidate = state.checklist.tovalidate;
      if (
        state.checklist &&
        state.checklist.tovalidate &&
        Number.isInteger(state.checklist.tovalidate) &&
        state.checklist.tovalidate > 0
      ) {
        newTovalidate = state.checklist.tovalidate - 1;
      }
      return {
        ...state, checklist: {
          ...state.checklist,
          tovalidate: newTovalidate
        }
      };
    case 'LOG_OUT':
      return INITIAL_STATE;
    default:
      return state;
  }
};

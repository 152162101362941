import React from 'react';
import { Platform, View } from 'react-native';

import { SVGLocal } from '_components/SVGLocal'

import { styles } from './styles'

export const UpperGradient = () => {
  let height = Platform.OS === 'web' ? 150 : 180
  return (
    <View style={styles.upperFixView}>
      <SVGLocal name={'upper_polygon'} width={'100%'} height={height} />
    </View>
  );
}


import { colors } from "_theme/colors";

export const commonStyles = {
    shadow: {
        shadowColor: colors.black_swood,
        shadowOffset: { width: 1, height: 6 },
        shadowOpacity: 0.5,
        shadowRadius: 8,
        elevation: 10,
    }
}
import React from 'react';
import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
} from 'react-native';
import { useSelector } from 'react-redux';
import { colors } from '_theme';
import { BackgroundWave } from '_components';
import picture from '_assets/pictures/thankyou_image/thankyou.png';

function ThankyouScreen({ navigation }) {
  const user = useSelector((state) => state.user.user);
  const todoNumber = useSelector((state) => state.user.checklist.todo);

  const onPressNext = () => {
    if (todoNumber === 0) {
      navigation.replace('Dashboard');
    } else {
      navigation.replace('List', { mode: 'todo' });
    }
  };

  const NextButton = () => {
    if (user) {
      return (
        <TouchableOpacity style={styles.buttonConnect} onPress={onPressNext}>
          <Text style={styles.buttonText}>SUIVANT</Text>
        </TouchableOpacity>
      );
    }
    return null;
  };

  const Indication = () => {
    if (user) {
      return null;
    }
    return (
      <Text style={styles.text}>Vous pouvez maintenant fermer cet onglet</Text>
    );
  };

  return (
    <BackgroundWave innerStyle={styles.container}>
      <Image style={styles.pictureLogo} source={picture} />
      <Text style={styles.text}>Merci pour votre avis !</Text>
      <Indication />
      <Text style={styles.textR}>Chez Swood, nous visons les étoiles !</Text>
      <NextButton />
    </BackgroundWave>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    color: colors.black_swood,
    fontSize: 24,
    fontFamily: 'VolteRounded-Semibold',
    marginTop: 20,
    marginRight: 20,
    marginLeft: 20,
    textAlign: 'center',
  },
  textR: {
    color: colors.pink_swood,
    fontSize: 24,
    fontFamily: 'VolteRounded-Semibold',
    marginTop: 20,
    marginRight: 20,
    marginLeft: 20,
    textAlign: 'center',
  },
  pictureLogo: {
    width: 280,
    height: 280,
  },
  buttonConnect: {
    height: 50,
    width: '90%',
    backgroundColor: colors.pink_swood,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 100,
    alignSelf: 'center',
    marginTop: 40,
  },
  buttonText: {
    color: 'white',
    fontSize: 18,
    fontFamily: 'VolteRounded-Semibold',
  },
});

export default ThankyouScreen;


import translation from '_assets/data/translation'

export const translate = ({ name = "", arrayIndex = -1, language = "en" }) => {
    let translatedText = "[ " + name + (arrayIndex >= 0 ? ('[' + arrayIndex + '] ') : ' ') + language + " ]"

    if (translation && translation[language] && translation[language][name]) {
        if (Array.isArray(translation[language][name])) {
            if (arrayIndex >= 0 && translation[language][name][arrayIndex]) {
                translatedText = translation[language][name][arrayIndex]
            }
        } else {
            translatedText = translation[language][name]
        }
    }

    return translatedText
}
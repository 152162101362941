import { tags } from '_views/EvaluationScreen/EvaluationConfig';
import { translate } from './translateManager'

export const getMonthYearFromTime = ({ time = undefined }) => {
  var d = new Date(time);
  if (isNaN(d)) { return null }
  var str = translate({ name: "monthList", arrayIndex: d.getMonth(), language: 'fr' }) + ' ' + d.getFullYear();
  return str;
};

export const getAverage = (perfRating, qualityRating, spiritRating) => {
  var rst = perfRating + qualityRating + spiritRating;
  rst = rst / 3;
  rst = Math.round(rst * 10) / 10;
  return rst;
};

export const convertStringToArray = (str) => {
  if (str === undefined || str === null || str === '') {
    return [];
  }
  var stg = str.replace(/ /g, '');
  return stg.split(',');
};

export const convertStringToTags = (str) => {
  var result = [];
  if (str === undefined || str === null || str === '') {
    return result;
  }
  var stg = str.replace(/ /g, '');
  var res = stg.split(',');
  res.forEach((txt) => {
    result.push({ text: txt });
  });
  return result;
};

export const getCorrectTags = ({ starValue, config }) => {
  var results = [];
  tags.forEach((item) => {
    if (
      item.stars.includes(starValue) === true &&
      matchConfig({ list: item.criteria, config }) === true
    ) {
      results.push(item);
    }
  });
  return results;
};

const matchConfig = ({ list, config }) => {
  var rst = false;
  list.forEach((item) => {
    if (item.value === config.value) {
      rst = true;
    }
  });
  return rst;
};

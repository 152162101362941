import React, { useEffect } from 'react';
import { View, Image, TouchableOpacity, FlatList, Text } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
//
import {
  getFeedbacksTodo,
  getFeedbacksToValidate,
} from '_services/fetchManager';
import { getMonthYearFromTime } from '_services/utilitiesManager';
import {
  setListToDo,
  setListToValidate,
} from '_redux/actions/feedbackActions';
// Image import
import { styles } from './ListScreenStyle';
import checkIcon from '_assets/pictures/check_icon/check.png';

function ListScreen({ route, navigation }) {
  const user = useSelector((state) => state.user.user);
  const todos = useSelector((state) => state.feedbacks.todo);
  const tovalid = useSelector((state) => state.feedbacks.tovalidate);
  const params = route.params;
  const dispatch = useDispatch();

  const onCellEvalPress = (item) => {
    if (params && params.mode && params.mode === 'todo') {
      navigation.navigate('evaluation', { id: item.linkToken });
    } else if (params && params.mode && params.mode === 'validate') {
      navigation.navigate('Review', { item: item });
    }
  };

  const getText = () => {
    if (getEvals().length > 0) {
      return '';
    } else {
      return 'Pas de contenu actuellement, merci de revenir plus tard';
    }
  };

  const getEvals = () => {
    if (params && params.mode && params.mode === 'todo') {
      return todos;
    }
    if (params && params.mode && params.mode === 'validate') {
      return tovalid;
    }
    return [];
  };

  useEffect(() => {
    if (user === undefined) {
      navigation.navigate('Login');
    } else {
      if (params && params.mode && params.mode === 'todo') {
        navigation.setOptions({ title: 'A évaluer' });
        getFeedbacksTodo({ token: user.token }).then((res) => {
          if (res.success === true && Array.isArray(res.data)) {
            dispatch(setListToDo(res.data));
          }
        });
      } else if (params && params.mode && params.mode === 'validate') {
        navigation.setOptions({ title: 'A valider' });
        getFeedbacksToValidate({ token: user.token }).then((res) => {
          if (res.success === true && Array.isArray(res.data)) {
            dispatch(setListToValidate(res.data));
          }
        });
      }
    }
  }, [user, navigation, params, dispatch]);

  return (
    <View style={styles.container}>
      <View style={styles.contentView}>
        <FlatList
          style={styles.list}
          data={getEvals()}
          renderItem={(item) => (
            <RenderItem
              onCellEvalPress={onCellEvalPress}
              item={item}
              mode={params.mode}
            />
          )}
          keyExtractor={(item) => item.linkToken}
        />
        <Text style={styles.noContentText}>{getText()}</Text>
      </View>
    </View>
  );
}

function RenderItem({ item, onCellEvalPress, mode }) {
  const onEvalPress = () => {
    onCellEvalPress(item.item);
  };

  const getTitle = () => {
    if (mode === 'todo') {
      return 'DONNER UN AVIS';
    } else if (mode === 'validate') {
      return "VOIR L'AVIS";
    }
  };

  return (
    <TouchableOpacity style={styles.cellContainer} onPress={onEvalPress}>
      <View style={styles.cellPictureContainer}>
        <View style={styles.cellPictureView}>
          <Image
            style={styles.cellPicture}
            source={{ uri: item.item.mission.swoody.pictureURL }}
            coverMode={'contains'}
          />
        </View>
      </View>
      <View style={styles.cellTextContainer}>
        <View style={styles.cellTextOnlyContainer}>
          <Text style={styles.cellJob}>{item.item.mission.title}</Text>
          <Text style={styles.cellName}>{item.item.mission.swoody.name}</Text>
          <Text style={styles.cellDate}>
            {"Chez "}
            {item.item.mission.client.companyName}
            {" depuis "}
            {getMonthYearFromTime({ time: item.item.mission.startDate })}
          </Text>
        </View>
        <RenderAction onEvalPress={onEvalPress} actionTitle={getTitle()} />
      </View>
    </TouchableOpacity>
  );
}

function RenderAction({ yes = true, id, onEvalPress, actionTitle }) {
  if (yes) {
    return (
      <View style={styles.cellActionContainer}>
        <TouchableOpacity style={styles.cellAction} onPress={onEvalPress}>
          <Text style={styles.cellActionTitle}>{actionTitle}</Text>
        </TouchableOpacity>
      </View>
    );
  }
  return (
    <View style={styles.cellActionContainer}>
      <Text style={styles.cellActionText}>Déja fait pour ce mois</Text>
      <Image style={styles.cellCheck} source={checkIcon} />
    </View>
  );
}

export default ListScreen;

import { Platform } from 'react-native';

const ApiHost = () => {
  let isWeb = Platform.OS === 'web'
  let isDev = !process?.env?.NODE_ENV || process?.env?.NODE_ENV === 'development'
  if (isWeb && isDev) {
    return 'http://localhost:3000/api';
  }
  return 'https://boomerang.swood.fr/api';
};

const end_point_user = '/user/';
const end_point_login = end_point_user + 'login/';
const end_point_user_forgot = end_point_user + 'forgot/';
const end_point_user_reset = end_point_user + 'reset/';
const end_point_dashboard = end_point_user + 'dashboard/';
const end_point_feedback = '/feedback/';
const end_point_feedback_self = end_point_feedback + 'self/';
const end_point_feedback_validate = end_point_feedback + 'validate/';
const end_point_feedback_todo = end_point_feedback + 'todo/';
const end_point_feedback_to_validate = end_point_feedback + 'tovalidate/';
const end_point_feedback_done = end_point_feedback + 'done/';
const end_point_feedback_user = end_point_feedback + 'foruser/';

const contentType = 'application/json';

export const logInUser = ({ password, email }) => {
  let url = ApiHost() + end_point_login;

  var body = {
    email: email,
    password: password,
  };
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  }).then((response) => response.json());
};

export const getDashboard = ({ token }) => {
  let url = ApiHost() + end_point_dashboard;
  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
    },
  }).then((response) => response.json());
};

export const getFeedbacks = ({ token }) => {
  let url = ApiHost() + end_point_feedback;
  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
    },
  }).then((response) => response.json());
};

export const getFeedbacksTodo = ({ token }) => {
  let url = ApiHost() + end_point_feedback_todo;
  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
    },
  }).then((response) => response.json());
};

export const getFeedbacksToValidate = ({ token }) => {
  let url = ApiHost() + end_point_feedback_to_validate;
  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
    },
  }).then((response) => response.json());
};

export const validateFeedback = ({ token, id, body }) => {
  let url = ApiHost() + end_point_feedback_validate + id;
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify(body),
  }).then((response) => response.json());
};

export const getFeedback = ({ id }) => {
  let url = ApiHost() + end_point_feedback + id;
  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      Accept: 'application/json',
    },
  }).then((response) => response.json());
};

export const updateFeedback = ({ id, body }) => {
  let url = ApiHost() + end_point_feedback + id;
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  }).then((response) => response.json());
};

export const getUsers = ({ token }) => {
  let url = ApiHost() + end_point_user;
  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
    },
  }).then((response) => response.json());
};

export const getMyFeedbacks = ({ token }) => {
  let url = ApiHost() + end_point_feedback_self;
  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
    },
  }).then((response) => response.json());
};

export const getFeedbacksDone = ({ token }) => {
  let url = ApiHost() + end_point_feedback_done;
  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
    },
  }).then((response) => response.json());
};

export const getFeedbackByUser = ({ token, id }) => {
  let url = ApiHost() + end_point_feedback_user + id;
  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
    },
  }).then((response) => response.json());
};

export const forgotPassword = ({ email }) => {
  let url = ApiHost() + end_point_user_forgot;
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      Accept: 'application/json',
    },
    body: JSON.stringify({ email: email }),
  }).then((response) => response.json());
};

export const resetPassword = ({ token, password }) => {
  let url = ApiHost() + end_point_user_reset + token;
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      Accept: 'application/json',
    },
    body: JSON.stringify({ password: password }),
  }).then((response) => response.json());
};

// check fetch response and parse it if needed
export const convertResponse = async (response) => {
  const text = await response.text() // Parse it as text
  if (text === '') {
    return null
  }
  try {
    const data = JSON.parse(text) // Try to parse it as json
    // Do your JSON handling here
    return data
  } catch (err) {
    // This probably means your response is text, do you text handling here
    return text
  }
}
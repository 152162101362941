import React from 'react';
import { View, Text, TouchableOpacity, TextInput } from 'react-native';
import { BackgroundWave } from '_components';
import { styles } from './PasswordScreenStyle';
import { forgotPassword } from '_services/fetchManager';

function PasswordScreen({ navigation }) {
  const [email, onChangeEmail] = React.useState('');

  const onSendPress = () => {
    forgotPassword({ email }).then((res) => {
      if (res.success === true) {
        navigation.goBack();
      }
    });
  };

  return (
    <BackgroundWave testID="forgottenPassword" innerStyle={styles.container} topWave={false}>
      <Text style={styles.textTitle}>Réinitialiser votre mot de passe</Text>
      <Text style={styles.textDesc}>
        Veuillez saisir votre adresse e-mail ci-dessous. Vous allez recevoir les
        instructions pour créer un nouveau mot de passe.
      </Text>
      <Text style={styles.inputTitleText}>Email</Text>
      <TextInput
        style={styles.inputName}
        onChangeText={(text) => onChangeEmail(text)}
        value={email}
        autoCompleteType="off"
      />
      <TouchableOpacity style={styles.buttonConnect} onPress={onSendPress}>
        <Text style={styles.buttonText}>RENVOYER LE MOT DE PASSE</Text>
      </TouchableOpacity>
    </BackgroundWave>
  );
}

export default PasswordScreen;

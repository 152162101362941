import { combineReducers } from 'redux';

import { configureStore } from './configureStore';


import { userReducer } from '../reducers/userReducer';
import { feedbackReducer } from '../reducers/feedbackReducer';

const configuredStore = () => {
    const rootReducer = combineReducers({
        /**
         * Register your reducers here.
         * @see https://redux.js.org/api-reference/combinereducers
         */
        user: userReducer,
        feedbacks: feedbackReducer,
    });

    return configureStore(rootReducer);
};
const { store, persistor } = configuredStore();
export { store, persistor };

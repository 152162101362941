const roles = [
  'consultant',
  'client',
  'rh',
  'pl',
  'commercial',
  'admin',
  'administrative',
];

export const rights = {
  view_evals_todo: roles,
  view_evals_toValidate: ['admin', 'administrative', 'rh', 'pl'],
  view_evals_mine: [
    'admin',
    'administrative',
    'rh',
    'pl',
    'commercial',
    'consultant',
  ],
  view_my_team: ['admin', 'administrative', 'rh', 'pl', 'commercial'],
  view_my_clients: ['admin', 'administrative', 'commercial'],
};

export const isAllowed = ({right = undefined, role = undefined}) => {
  if (right === undefined || role === undefined) {
    return false;
  }
  if (roles.includes(role) === false) {
    return false;
  } else if (Array.isArray(right) && right.includes(role)) {
    return true;
  }
  return false;
};

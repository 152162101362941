import { applyMiddleware, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';

import AsyncStorage from '@react-native-community/async-storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

const persistConfig = {
    key: 'root',
    storage: AsyncStorage,
    stateReconciler: autoMergeLevel2,
    whitelist: [
        "user",
        // "feedbacks"
    ]
};

export const configureStore = (rootReducer) => {

    const persistedReducer = persistReducer(persistConfig, rootReducer);

    const store = createStore(persistedReducer, applyMiddleware());
    const persistor = persistStore(store);

    return { store, persistor };
};

import React from 'react';
import { Platform, View } from 'react-native';

import { SVGLocal } from '_components/SVGLocal'

import { styles } from './styles'

export const BottomGradient = () => {
  let height = Platform.OS === 'web' ? 150 : 180
  return (
    <View style={styles.bottomFixView}>
      <SVGLocal name={'bottom_polygon'} alt="BottomColorView" width={'100%'} height={height} />
    </View>
  );
}




import { colors, fonts, metrics } from '_theme';
import { Platform, StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    backgroundColor: colors.white_swood,
    justifyContent: 'center',
    alignItems: 'center',
  },
  keyboardAvoiding: {
    flex: 1,
  },
  progressBarContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: colors.white_swood,
  },
  progressBarGradient: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  progressBar: {
  },
  evalStepFrame: {
    flex: 1,
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 450,
    marginHorizontal: metrics.spacing.inset.m,
    marginVertical: metrics.spacing.margin.m * 4,
  },
  scroll: {
    height: 100,
  },
  scrollInner: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  textTitle: {
    color: colors.black_swood,
    fontSize: 24,
    textAlign: 'center',
    marginTop: 20,
    marginRight: 16,
    marginLeft: 16,
    fontFamily: 'VolteRounded-Semibold',
  },
  textJobQuestion: {
    color: colors.black_swood,
    textAlign: 'center',
  },
  textJob: {
    marginTop: 5,
  },
  textSince: {
    color: colors.black_swood,
    fontSize: 12,
    textAlign: 'center',
    marginTop: 10,
    marginRight: 16,
    marginLeft: 16,
    fontFamily: 'VolteRounded-Semibold',
  },
  textQuestion: {
    marginTop: 20,
    marginBottom: 20,
    marginRight: 16,
    marginLeft: 16,
  },
  viewDesc: {
    marginTop: 10,
    backgroundColor: 'white',
    borderRadius: 60,
    shadowColor: colors.black_swood,
    shadowOffset: { width: 1, height: 6 },
    shadowOpacity: 0.5,
    shadowRadius: 8,
    elevation: 3,
  },
  textDesc: {
    fontFamily: 'VolteRounded-Semibold',
    margin: 10,
    color: colors.black_swood,
  },
  buttonText: {
    fontSize: 18,
    color: 'white',
    fontFamily: 'VolteRounded-Semibold',
  },
  buttonCommentView: {
    flexDirection: 'row',
    marginTop: 5,
    backgroundColor: colors.grey_light,
    padding: 8,
    borderRadius: 30,
  },
  buttonCommentView2: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 30,
  },
  textWarning: {
    fontSize: 14,
    color: colors.pink_swood,
    fontFamily: 'VolteRounded-Semibold',
    marginLeft: 20,
    marginRight: 20,
    textAlign: 'center',
  },
  buttonCommentImage: {
    width: 20,
    height: 20,
    marginRight: 10,
  },
  buttonCommentText: {
    fontSize: 14,
    color: colors.black_swood,
    fontFamily: 'VolteRounded-Semibold',
  },
  commentFieldContainer: {
    width: '100%',
    marginTop: 20,
  },
  commentFieldView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: colors.grey_light,
    marginRight: 16,
    marginLeft: 16,
    borderRadius: 8,
  },
  commentFieldIcon: {
    width: 20,
    height: 20,
    marginRight: 10,
    marginLeft: 10,
    marginTop: 10,
    marginBottom: 10,
  },
  commentFieldText: {
    fontSize: 14,
    color: colors.black_swood,
    fontFamily: 'VolteRounded-Semibold',
    margin: 8,
  },
  commentFieldButtonView: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  commentFieldButtonText: {
    marginRight: 15,
    marginLeft: 15,
    color: 'black',
    fontFamily: 'VolteRounded-Semibold',
    fontSize: 14,
    marginBottom: 10,
  },
  commentFieldInput: {
    fontSize: 14,
    color: colors.black_swood,
    fontFamily: 'VolteRounded-Semibold',
    flexShrink: 1,
    marginTop: 5,
    flex: 1,
    backgroundColor: colors.grey_light,
    borderRadius: 8,
    fontWeight: 'normal',
    textAlign: 'auto',
    paddingTop: 0,
  },
  bottomButtonsView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
    maxWidth: 450,
    paddingVertical: metrics.spacing.inset.s,
    paddingHorizontal: metrics.spacing.inset.m,
    overflow: 'hidden'
  },
  buttons: {
    marginVertical: 0,
    marginHorizontal: 0,
    paddingHorizontal: 0,
    flex: 1,
    maxWidth: "50%"
  },
  textCriteria: {
    color: colors.green_swood,
  },
});

export const SET_USER = 'SET_USER';
export const SET_CHECKLIST = 'SET_CHECKLIST';
export const LOG_OUT = 'LOG_OUT';
export const REMOVE_TODO = 'REMOVE_TODO';
export const REMOVE_TOVALIDATE = 'REMOVE_TOVALIDATE';

export const setUserAction = (user) => ({
  type: SET_USER,
  data: user,
});

export const setUserChecklist = (checklist) => ({
  type: SET_CHECKLIST,
  data: checklist,
});

export const removeOneToDo = () => ({
  type: REMOVE_TODO,
  data: undefined,
});

export const removeOneToValidate = () => ({
  type: REMOVE_TOVALIDATE,
  data: undefined,
});

export const logOutUser = () => ({
  type: LOG_OUT,
  data: undefined,
});

import { colors } from '_theme';
import { Platform, StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: Platform.OS === 'web' ? '100vh' : '100%',
    margin: 0,
    padding: 0,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
  },
  headerView: {
    backgroundColor: 'white',
    height: 160,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    shadowColor: colors.black_swood,
    shadowOffset: { width: 1, height: 6 },
    shadowOpacity: 0.5,
    shadowRadius: 8,
    elevation: 3,
  },
  contentView: {
    flex: 1,
  },
  imgLogo: {
    width: 100,
    height: 60,
    resizeMode: 'contain',
    marginLeft: 20,
  },
  roundButton: {
    backgroundColor: colors.grey_light,
    width: 40,
    height: 40,
    margin: 10,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
  },
  buttonView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imgIcon: {
    width: 20,
    height: 20,
  },
  list: {
    flex: 1,
    marginTop: 5,
    //backgroundColor: 'yellow',
  },
  cellContainer: {
    height: 110,
    marginRight: 15,
    marginTop: 10,
    marginBottom: 5,
    marginLeft: 15,
    borderRadius: 10,
    shadowColor: colors.black_swood,
    shadowOffset: { width: 1, height: 6 },
    shadowOpacity: 0.5,
    shadowRadius: 8,
    elevation: 3,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    backgroundColor: 'white',
  },
  cellPictureContainer: {
    width: 90,
    alignItems: 'center',
    margin: 8,
  },
  cellTextContainer: {
    flex: 1,
    marginTop: 15,
    justifyContent: 'space-between',
  },
  cellTextOnlyContainer: {
    justifyContent: 'flex-start',
  },
  cellJob: {
    fontFamily: 'VolteRounded-Semibold',
    fontSize: 12,
    color: colors.grey_66,
  },
  cellName: {
    fontFamily: 'VolteRounded-Semibold',
    fontSize: 14,
    color: colors.black_33,
  },
  cellDate: {
    fontFamily: 'VolteRounded-Semibold',
    fontSize: 10,
    color: colors.grey_66,
  },
  cellPictureView: {
    width: 66,
    height: 66,
    backgroundColor: colors.pink_swood,
    borderRadius: 60,
    overflow: 'hidden',
  },
  cellPicture: {
    flex: 1,
    resizeMode: 'contain',
  },
  cellAverageView: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  cellAverageText: {
    color: colors.black_swood,
    fontSize: 14,
    fontFamily: 'VolteRounded-Semibold',
    marginRight: 2,
  },
  cellStar: {
    width: 12,
    height: 12,
    marginLeft: 2,
    marginBottom: 2,
  },
  cellActionContainer: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
    marginBottom: 8,
    marginRight: 8,
  },
  cellAction: {
    backgroundColor: colors.black_swood,
    borderRadius: 32,
    alignSelf: 'flex-end',
  },
  cellActionTitle: {
    color: 'white',
    fontSize: 12,
    fontFamily: 'VolteRounded-Semibold',
    marginRight: 15,
    marginLeft: 15,
  },
  cellActionText: {
    color: colors.black_swood,
    fontSize: 12,
    fontFamily: 'VolteRounded-Semibold',
    marginRight: 5,
  },
  cellCheck: {
    width: 12,
    height: 9,
    marginTop: 2,
  },
  noContentText: {
    position: 'absolute',
    fontSize: 18,
    fontFamily: 'VolteRounded-Semibold',
    color: colors.black_swood,
    marginTop: 10,
    alignSelf: 'center',
  },
});

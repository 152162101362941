import { StyleSheet, Platform } from 'react-native';
import { colors } from '_theme';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: Platform.OS === 'web' ? '100vh' : '100%',
    margin: 0,
    padding: 0,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    backgroundColor: 'white',
  },
  dummyText: {
    color: 'black',
    fontSize: 50,
    fontFamily: 'VolteRounded-Semibold',
  },
  tabContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    //backgroundColor: 'pink',
  },
  contentContainer: {
    flex: 1,
    //backgroundColor: 'yellow',
  },
  tabButton: {
    height: 50,
    width: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tabTitleSelected: {
    fontFamily: 'VolteRounded-Semibold',
    fontSize: 14,
    color: colors.black_33,
  },
  tabTitleNormal: {
    fontFamily: 'VolteRounded-Semibold',
    fontSize: 14,
    color: colors.grey_66,
  },
});

import React, { useEffect } from 'react';
import { View, Text, TouchableOpacity, FlatList } from 'react-native';
import { useSelector } from 'react-redux';
//
import { styles } from './EvalListScreenStyle';
import { RectangleGradient, ReviewComponent } from '_components';
//
import { getMyFeedbacks, getFeedbacksDone } from '_services/fetchManager';

function EvalListScreen({ navigation }) {
  const user = useSelector((state) => state.user.user);
  const [tab, onChangeTab] = React.useState('mine');
  const [reviewsMine, onSetReviewsMine] = React.useState([]);
  const [reviewsOther, onSetReviewsOther] = React.useState([]);

  useEffect(() => {
    if (user === undefined) {
      navigation.navigate('Login');
    } else {
      getMyFeedbacks({ token: user.token }).then((res) => {
        if (res.success === true) {
          onSetReviewsMine(res.data);
        }
      });
      getFeedbacksDone({ token: user.token }).then((res) => {
        if (res.success === true) {
          onSetReviewsOther(res.data);
        }
      });
    }
  }, [navigation, user]);

  return (
    <View style={styles.container}>
      <View style={styles.tabContainer}>
        <TouchableOpacity
          style={styles.tabButton}
          onPress={() => onChangeTab('mine')}>
          <Text
            style={
              tab === 'mine' ? styles.tabTitleSelected : styles.tabTitleNormal
            }>
            Feedback reçus
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.tabButton}
          onPress={() => onChangeTab('other')}>
          <Text
            style={
              tab === 'other' ? styles.tabTitleSelected : styles.tabTitleNormal
            }>
            Feedback donnés
          </Text>
        </TouchableOpacity>
      </View>
      <RectangleGradient start={tab === 'mine' ? true : false} />
      <View style={styles.contentContainer}>
        <FlatList
          style={styles.list}
          data={tab === 'mine' ? reviewsMine : reviewsOther}
          renderItem={(item) => (
            <ReviewComponent
              mode="small"
              feedback={item.item}
              name={
                tab === 'other'
                  ? item.item.mission.swoody.name
                  : item.item.mission.reviewer.name
              }
            />
          )}
          keyExtractor={(item) => item.linkToken}
        />
      </View>
    </View>
  );
}

export default EvalListScreen;

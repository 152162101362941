const spacing = {
    margin: {
        l: 24,
        m: 16,
        s: 8,
    },
    inset: {
        l: 24,
        m: 16,
        s: 8,
    },
}

const borderWidth = {
    l: 4,
    m: 2,
    s: 1,
}
const borderRadius = {
    full: 10000,
    xl: 32,
    m: 8,
}

export const metrics = {
    borderWidth,
    borderRadius,
    spacing,
};

import { StyleSheet, Platform } from 'react-native';
import { colors } from '_theme';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: Platform.OS === 'web' ? '100vh' : '100%',
    margin: 0,
    padding: 0,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    backgroundColor: 'white',
  },
  searchContainer: {
    flexDirection: 'row',
    backgroundColor: colors.grey_light,
    margin: 20,
    height: 35,
    alignItems: 'center',
    borderRadius: 10,
  },
  searchImage: {
    margin: 10,
  },
  searchInput: {
    flex: 1,
    fontFamily: 'VolteRounded-Semibold',
    color: colors.grey_66,
  },
  teamContainer: {
    flex: 1,
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  cellCard: {
    width: 160,
    height: 180,
    marginRight: 10,
    marginLeft: 10,
    borderRadius: 8,
    shadowColor: colors.black_swood,
    shadowOffset: { width: 1, height: 6 },
    shadowOpacity: 0.5,
    shadowRadius: 8,
    elevation: 3,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    marginBottom: 20,
  },
});

export default styles;

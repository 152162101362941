import React from 'react';
import { StyleSheet, View, Image, Text, Platform } from 'react-native';
import { getMonthYearFromTime } from '_services/utilitiesManager';
import { colors } from '_theme';

export const ProfileHeader = ({ mission, user, size = 'big' }) => {
  const getDateText = () => {
    if (size === 'big') {
      return (
        'Chez ' +
        mission.client.companyName +
        ' depuis ' +
        getMonthYearFromTime({ time: mission.startDate })
      );
    } else {
      return 'Chez ' + mission.client.companyName;
    }
  };

  const OrderedText = () => {
    if (size === 'big') {
      return (
        <View>
          <Text style={styles.textName}>{user.name}</Text>
          <Text style={styles.textJob}>{mission.title}</Text>
        </View>
      );
    } else {
      return (
        <View>
          <Text style={styles.textJobSmall}>{mission.title}</Text>
          <Text style={styles.textNameSmall}>{user.name}</Text>
        </View>
      );
    }
  };

  return (
    <View style={styles.container}>
      <View
        style={size === 'big' ? styles.pictureView : styles.pictureViewSmall}>
        <Image
          style={styles.pictureFace}
          source={{ uri: user.pictureURL }}
          coverMode={'contains'}
        />
      </View>
      <OrderedText />
      <Text style={size === 'big' ? styles.textSince : styles.textSinceSmall}>
        {getDateText()}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  pictureView: {
    width: 110,
    height: 110,
    backgroundColor: colors.pink_swood,
    borderRadius: 60,
    marginTop: Platform.OS === 'web' ? 20 : 60,
    overflow: 'hidden',
  },
  pictureViewSmall: {
    width: 90,
    height: 90,
    backgroundColor: colors.pink_swood,
    borderRadius: 45,
    overflow: 'hidden',
  },
  pictureFace: {
    flex: 1,
    resizeMode: 'contain',
  },
  textName: {
    color: colors.black_swood,
    fontSize: 24,
    textAlign: 'center',
    marginTop: 10,
    marginRight: 16,
    marginLeft: 16,
    fontFamily: 'VolteRounded-Semibold',
  },
  textNameSmall: {
    color: colors.black_33,
    fontSize: 14,
    textAlign: 'center',
    fontFamily: 'VolteRounded-Semibold',
    margin: 2,
  },
  textJob: {
    color: colors.black_swood,
    fontSize: 18,
    textAlign: 'center',
    marginRight: 16,
    marginLeft: 16,
    fontFamily: 'VolteRounded-Semibold',
    marginTop: 5,
  },
  textJobSmall: {
    color: colors.grey_66,
    fontSize: 12,
    textAlign: 'center',
    fontFamily: 'VolteRounded-Semibold',
    margin: 2,
    marginTop: 8,
  },
  textSince: {
    color: colors.grey_66,
    fontSize: 12,
    textAlign: 'center',
    marginRight: 16,
    marginLeft: 16,
    marginTop: 5,
    fontFamily: 'VolteRounded-Semibold',
  },
  textSinceSmall: {
    color: colors.grey_66,
    fontSize: 12,
    textAlign: 'center',
    fontFamily: 'VolteRounded-Semibold',
    margin: 2,
  },
});


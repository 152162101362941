import React from 'react';
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native';
import star_outline from '_assets/pictures/star_outline/star.png';
import star_full from '_assets/pictures/star_full/star.png';

export const StarComponent = ({ starsNumber = 5, value = 0, onPress }) => {
  return (
    <View style={styles.container}>
      {(() => {
        const tab = [];
        for (let i = 0; i < starsNumber; i++) {
          tab.push(
            <TouchableOpacity key={i} onPress={() => onPress(i + 1)}>
              <Image
                style={styles.star}
                source={i < value ? star_full : star_outline}
                resizeMode="contain"
              />
            </TouchableOpacity>,
          );
        }
        return tab;
      })()}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 35,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'stretch',
    marginRight: 12,
    marginLeft: 12,
  },
  star: {
    width: 30,
    height: 30,
  },
});

import React from 'react';
import { Platform, View } from 'react-native';


import { SVGLocalReaderCrossPlatform } from './SVGLocalReaderCrossPlatform'


// Boomerang logo
import BoomerangLogo from '_assets/pictures/boomerang_logo/boomerang_logo.svg';
import BoomerangLogoWhite from '_assets/pictures/boomerang_logo/boomerang_logo_white.svg';
// Swood logo
import SwoodLogo from '_assets/pictures/logo_swood/logo_swood.svg';
// Boomerang by swood logo
import BoomerangSwood from '_assets/pictures/boomerang_logo/boomerang_swood.svg';
// BIG CHECK ICON
import BigCheck from '_assets/pictures/big_check_icon/big_check.svg';
// TEAM ICON
import TeamIcon from '_assets/pictures/team_icon/team_icon.svg';
// BARS ICON
import BarsIcon from '_assets/pictures/bars_icon/bars_icon.svg';
// EVALS ICON
import EvalsIcon from '_assets/pictures/eval_icon/evals_icon.svg';
// Bottom arrow icon
import BottomArrowIcon from '_assets/pictures/bottom_arrow/arrow.svg';
// Top arrow icon
import TopArrowIcon from '_assets/pictures/top_arrow/arrow.svg';
// Back arrow icon
import BackArrowIcon from '_assets/pictures/back_arrow/back_arrow.svg';
// Trash can icon
import TrashCanIcon from '_assets/pictures/trash_icon/trash.svg';
// Close icon
import CloseIcon from '_assets/pictures/close_icon/close.svg';
// Send icon
import SendIcon from '_assets/pictures/send_icon/send.svg';
// bottom wave
import BottomPolygon from '_assets/pictures/bottom_polygon/polygon_svg.svg';
// upper wave
import UpperPolygon from '_assets/pictures/upper_polygon/polygon_svg.svg';
// rectangle gradient
import RectangleGradient from '_assets/pictures/gradient_svg/rectangle.svg';
// rectangle gradient
import ToolTipIcon from '_assets/pictures/tooltip_icon/tooltip_icon.svg';



export const SVGLocal = ({ name, ...otherProps }) => {
    switch (name) {
        case 'big_check':
            return <SVGLocalReaderCrossPlatform SVGImported={BigCheck} {...otherProps} />
        case 'team_icon':
            return <SVGLocalReaderCrossPlatform SVGImported={TeamIcon} {...otherProps} />
        case 'bars_icon':
            return <SVGLocalReaderCrossPlatform SVGImported={BarsIcon} {...otherProps} />
        case 'evals_icon':
            return <SVGLocalReaderCrossPlatform SVGImported={EvalsIcon} {...otherProps} />
        case 'bottom_arrow':
            return <SVGLocalReaderCrossPlatform SVGImported={BottomArrowIcon} {...otherProps} />
        case 'top_arrow':
            return <SVGLocalReaderCrossPlatform SVGImported={TopArrowIcon} {...otherProps} />
        case 'back_arrow':
            return <SVGLocalReaderCrossPlatform SVGImported={BackArrowIcon} {...otherProps} />
        case 'trash_can':
            return <SVGLocalReaderCrossPlatform SVGImported={TrashCanIcon} {...otherProps} />
        case 'close':
            return <SVGLocalReaderCrossPlatform SVGImported={CloseIcon} {...otherProps} />
        case 'send':
            return <SVGLocalReaderCrossPlatform SVGImported={SendIcon} {...otherProps} />
        case 'logo_swood':
            return <SVGLocalReaderCrossPlatform SVGImported={SwoodLogo} {...otherProps} />
        case 'boomerang':
            return <SVGLocalReaderCrossPlatform SVGImported={BoomerangLogo} {...otherProps} />
        case 'boomerang_swood':
            return <SVGLocalReaderCrossPlatform SVGImported={BoomerangSwood} {...otherProps} />
        case 'boomerang_white':
            return <SVGLocalReaderCrossPlatform SVGImported={BoomerangLogoWhite} {...otherProps} />
        case 'bottom_polygon':
            return <SVGLocalReaderCrossPlatform SVGImported={BottomPolygon} {...otherProps} />
        case 'upper_polygon':
            return <SVGLocalReaderCrossPlatform SVGImported={UpperPolygon} {...otherProps} />
        case 'rectangle_gradient':
            return <SVGLocalReaderCrossPlatform SVGImported={RectangleGradient} {...otherProps} />
        case 'tooltip_icon':
            return <SVGLocalReaderCrossPlatform SVGImported={ToolTipIcon} {...otherProps} />
        default:
            return null
    }
}



import React from 'react';
import { StyleSheet, View, Image } from 'react-native';

import { metrics, colors } from '_theme';
import userIcon from '_assets/pictures/user_icon/user.png';

export const ProfileButton = () => {
    return (
        <View style={styles.profile} >
            <Image style={styles.imgIcon} source={userIcon} />
        </View>
    );
}

const styles = StyleSheet.create({
    profile: {
        backgroundColor: colors.grey_light,
        width: 40,
        height: 40,
        padding: 10,
        borderRadius: 20,
    },
    imgIcon: {
        width: "100%",
        height: "100%"
    }
});
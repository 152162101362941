import React, { useEffect } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
//
import { validateFeedback } from '_services/fetchManager';
import { removeOneToValidate } from '_redux/actions/userActions';
import { deleteToValidate } from '_redux/actions/feedbackActions';
//
import { styles } from './ReviewScreenStyle';
import { ReviewComponent } from '_components';

function ReviewScreen({ route, navigation }) {
  const params = route.params;
  const feedback = params.item;
  const body = {};
  const user = useSelector((state) => state.user.user);
  const todoNumber = useSelector((state) => state.user.checklist.tovalidate);
  const dispatch = useDispatch();

  const onCommentDelete = ({ criteria }) => {
    if (criteria === 'efficacity') {
      body.perfComment = '';
    }
    if (criteria === 'quality') {
      body.qualityComment = '';
    }
    if (criteria === 'spirit') {
      body.spiritComment = '';
    }
  };

  const onValidatePress = () => {
    validateFeedback({ token: user.token, id: feedback.linkToken, body }).then(
      (res) => {
        if (res.success === true) {
          dispatch(removeOneToValidate());
          dispatch(deleteToValidate(feedback.id));
          if (todoNumber === 0) {
            navigation.navigate('Dashboard');
          } else {
            navigation.goBack();
          }
        }
      },
    );
  };

  useEffect(() => {
    if (feedback && feedback.mission && feedback.mission.swoody) {
      navigation.setOptions({ title: feedback.mission.swoody.name });
    }
  }, [feedback, navigation]);

  return (
    <View style={styles.container}>
      <ReviewComponent
        mode="valid"
        feedback={feedback}
        onCommentDelete={onCommentDelete}
      />
      <TouchableOpacity style={styles.buttonConnect} onPress={onValidatePress}>
        <Text style={styles.buttonText}>VALIDER</Text>
      </TouchableOpacity>
    </View>
  );
}

export default ReviewScreen;

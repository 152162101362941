import React from 'react';
import { StyleSheet, View } from 'react-native';

import { SVGLocal } from '_components/SVGLocal';

export const BackButton = () => {
    return (
        <View style={styles.back} >
            <SVGLocal name={'back_arrow'} />
        </View>
    );
}

const styles = StyleSheet.create({
    back: {
        width: 20,
        height: 20,
    },
});
import React from 'react';
import { View, Text, TouchableOpacity, TextInput } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import { BackgroundWave } from '_components';
import { styles } from './ResetScreenStyle';
import { resetPassword } from '_services/fetchManager';
import { logOutUser } from '_redux/actions/userActions';
import Alert, { AlertRoot } from '_navigation/AlertCrossPlatform';

function ResetScreen({ navigation, route }) {

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const isLoggedIn = user?.token ? true : false;

  const [password, onChangePassword] = React.useState('');
  const [confirmation, onChangeConfirmation] = React.useState('');
  const params = route.params;


  const leaveScreen = () => {
    if (isLoggedIn) {
      dispatch(logOutUser());
    }
    navigation.reset({
      index: 0,
      routes: [
        {
          name: 'Login',
        },
      ],
    })
  }
  const checkFields = () => {
    let fieldsTestAnswer = { result: false, message: 'Erreur' }
    let isPasswordTypesOk = checkPasswordTypes(password);
    let isPasswordsSame = password === confirmation;
    if (isPasswordTypesOk && isPasswordsSame) {
      fieldsTestAnswer.result = true
    }
    if (!isPasswordTypesOk) {
      fieldsTestAnswer.result = false
      fieldsTestAnswer.message = 'Votre mot de passe doit contenir 8 caractères minimum dont au moins 1 chiffre.'
    }
    if (isPasswordTypesOk && !isPasswordsSame) {
      fieldsTestAnswer.result = false
      fieldsTestAnswer.message = 'Votre mot de passe et la confirmation de correspondent pas.'
    }
    return fieldsTestAnswer
  }
  const checkPasswordTypes = (password) => {
    let regex8Char1Dec = /^(?=.*[a-z])(?=.*\d)[A-Za-z\d!\"#$%&'\(\)\*\+\,\-\.\/:;<=>?@\[\]^_`\{\|\}~]{8,}/
    let testString = regex8Char1Dec.test(
      password
    )
    return testString
  }
  const onSavePasswordPress = () => {
    let fieldsTestAnswer = checkFields()
    if (fieldsTestAnswer.result) {
      var token = params.token;
      resetPassword({ password, token }).then((res) => {
        if (res.success === true) {
          leaveScreen()
        }
      });
    } else {
      Alert.alert(
        "Mot de passe invalide.",
        fieldsTestAnswer.message,
        [
          {
            text: 'Ok',
            style: 'cancel',
          },
        ],
        { cancelable: false },
      );
    }
  }

  return (
    <BackgroundWave innerStyle={styles.container} topWave={false}>
      <Text style={styles.inputTitleText}>Nouveau mot de passe*</Text>
      <TextInput
        style={styles.inputName}
        onChangeText={(text) => onChangePassword(text)}
        value={password}
        secureTextEntry={true}
      />
      <Text style={styles.indication}>
        8 caractères minimum dont au moins 1 chiffre
      </Text>
      <Text style={styles.inputTitleText}>Confirmez ce mot de passe*</Text>
      <TextInput
        style={styles.inputName}
        onChangeText={(text) => onChangeConfirmation(text)}
        value={confirmation}
        secureTextEntry={true}
      />
      <TouchableOpacity style={styles.buttonConnect} onPress={onSavePasswordPress}>
        <Text style={styles.buttonText}>ENREGISTRER</Text>
      </TouchableOpacity>
      <AlertRoot />
    </BackgroundWave>
  );
}

export default ResetScreen;

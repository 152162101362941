import React from 'react';
import { Platform, Image } from 'react-native';

export const SVGLocalReaderCrossPlatform = ({
    SVGImported,
    style,
    width = "100%",
    height = "100%",
    resizeMode = "contain",
    ...otherProps
}) => {
    if (Platform.OS === 'web') {
        return (
            <img src={SVGImported} style={{ resizeMode }} {...{ ...otherProps, width, height }} />
        );
    } else {
        return (
            <SVGImported {...{ ...otherProps, style, resizeMode, width, height }} />
        );
    }
}



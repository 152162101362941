import React from 'react';
import { Pressable, View, Text } from 'react-native';
import { getStyles } from './styles';

//TODO : add logo before and after logics and styles ( maybe a fontIcon could be cool )

export const ButtonStyled = ({
    style,
    textStyle,
    size,
    design,
    disabled,
    before,
    after,
    children,
    color,
    ...otherProps
}) => {
    let styles = getStyles({ size, design, disabled, color })
    return (
        <Pressable style={[styles.container, style]} {...{ ...otherProps, disabled }}>
            {before && (<View style={styles.before} >{before()}</View>)}
            <View style={styles.textContainer} >
                <Text style={[styles.text, textStyle]}>{children}</Text>
            </View>
            {after && (<View style={styles.after} >{after()}</View>)}
        </Pressable>
    );
};
import React from 'react';
import {
    Platform,
    View,
    StatusBar,
    useWindowDimensions,
    KeyboardAvoidingView
} from 'react-native';
import { useHeaderHeight } from '@react-navigation/stack';
//
import { UpperGradient, BottomGradient } from '_components/UIBase/design/GradientShapes';

import { styles } from './styles'

export const BackgroundWave = ({ children, style, innerStyle, topWave = true, bottomWave = true, ...otherProps }) => {

    const headerHeight = useHeaderHeight();
    const windowHeight = useWindowDimensions().height;
    return (
        <View style={[styles.container, { minHeight: windowHeight - headerHeight }, style]} {...otherProps}>
            <StatusBar
                translucent
                backgroundColor={'transparent'}
                barStyle={'light-content'}
            />
            {topWave && <UpperGradient />}
            {bottomWave && <BottomGradient />}
            <KeyboardAvoidingView
                behavior={Platform.OS === "ios" ? "padding" : "height"}
                style={[styles.innerContainer, innerStyle]}
            >
                {children && children}
            </KeyboardAvoidingView>
        </View>
    );
}

import React, { useEffect } from 'react';
import { View, TouchableOpacity, Text, StatusBar } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
//
import { BackgroundWave, ListHeader, SVGLocal } from '_components';
import { styles } from './DashboardScreenStyle';
//
import { rights, isAllowed } from '_services/roleManager';
import { getDashboard } from '_services/fetchManager';
import { setUserChecklist } from '_redux/actions/userActions';

function DashboardScreen({ navigation }) {
  const user = useSelector((state) => state.user.user);
  const todo = useSelector((state) => state.user.checklist.todo);
  const tovalid = useSelector((state) => state.user.checklist.tovalidate);
  const dispatch = useDispatch();

  const onTeamPress = () => {
    navigation.navigate('Team');
  };

  const onToValidatePress = () => {
    navigation.navigate('List', { mode: 'validate' });
  };

  const onToDoPress = () => {
    navigation.navigate('List', { mode: 'todo' });
  };

  const onMyEvalPress = () => {
    navigation.navigate('Feedbacks');
  };

  useEffect(() => {
    if (user === undefined) {
      navigation.navigate('Login');
    } else {
      getDashboard({ token: user.token }).then((res) => {
        if (res.success === true) {
          dispatch(setUserChecklist(res.data));
        }
      });
    }
  }, [navigation, user, dispatch]);

  return (
    <BackgroundWave testID="dashboard" innerStyle={styles.container} topWave={false}>
      <StatusBar
        barStyle={'dark-content'}
      />
      <View style={styles.actionsView}>
        <ActionCell
          imageName={'big_check'}
          title={'A valider'}
          action={onToValidatePress}
          display={isAllowed({
            role: user.role,
            right: rights.view_evals_toValidate,
          })}
          highlight={tovalid}
        />
        <ActionCell
          imageName={'bars_icon'}
          title={'A évaluer'}
          action={onToDoPress}
          display={isAllowed({ role: user.role, right: rights.view_evals_todo })}
          highlight={todo}
        />
        <ActionCell
          imageName={'evals_icon'}
          title={'Mes évaluations'}
          action={onMyEvalPress}
          display={isAllowed({ role: user.role, right: rights.view_evals_mine })}
        />
        <ActionCell
          imageName={'team_icon'}
          title={'Mon équipe'}
          action={onTeamPress}
          display={isAllowed({ role: user.role, right: rights.view_my_team })}
        />
      </View>
    </BackgroundWave>
  );
}

function ActionCell({
  imageName,
  action,
  title,
  display = true,
  highlight = -1,
}) {
  if (display === false) {
    return null;
  }
  if (highlight > 0) {
    return (
      <TouchableOpacity
        style={[styles.cellButton, styles.cellButtonHighlight]}
        onPress={action}>
        <View style={styles.cellImageView}>
          <SVGLocal
            name={imageName}
            height={styles.cellImage.height}
            width={styles.cellImage.width}
          />
        </View>
        <View style={styles.cellTextHighlight}>
          <Text style={styles.cellText}>{title}</Text>
          <View style={styles.cellPoint}>
            <Text style={styles.cellPointText}>{highlight}</Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  }
  if (highlight === 0) {
    return (
      <TouchableOpacity
        style={styles.cellButton}
        disabled={true}
        onPress={action}>
        <View style={styles.cellImageView}>
          <SVGLocal
            name={imageName}
            height={styles.cellImage.height}
            width={styles.cellImage.width}
          />
        </View>
        <Text style={styles.cellText}>{title}</Text>
        <View style={styles.cellOpace} />
      </TouchableOpacity>
    );
  }
  return (
    <TouchableOpacity style={styles.cellButton} onPress={action}>
      <View style={styles.cellImageView}>
        <SVGLocal
          name={imageName}
          height={styles.cellImage.height}
          width={styles.cellImage.width}
        />
      </View>
      <Text style={styles.cellText}>{title}</Text>
    </TouchableOpacity>
  );
}

export default DashboardScreen;

import React from 'react';
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native';

export const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    linearGradient: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    logo: {
    },
});

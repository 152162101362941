import * as React from 'react';
import { View, Text, Button, Image, Pressable, SafeAreaView, Animated } from 'react-native';
import { useSelector } from 'react-redux';

import { styles } from './styles';

import { Title, Paragraph, SVGLocal, CloseButton, BackButton, ProfileButton } from '_components';


export const NavigationHeader = ({
    mode,
    layout,
    insets,
    scene,
    previous,
    navigation,
    styleInterpolator,
}) => {
    const { title } = scene.descriptor.options;
    let currentScreen = scene.route.name;
    let options = scene.descriptor.options;
    let hideHeader = false;
    let gotLeftNull = options?.headerLeft === null;
    let gotLeft = options?.headerLeft
    let gotRight = options?.headerRight

    const user = useSelector((state) => state.user.user);
    const isLoggedIn = user?.token ? true : false;

    const onUserPress = () => {
        navigation.navigate('MonProfile');
    }
    return (
        <View
            style={[
                styles.container,
                { opacity: hideHeader ? 0 : 1, paddingTop: insets.top },
                !options?.noShadow ? styles.containerWithShadow : {},
                options?.smallHeader ? styles.innerContainerSmall : {}
            ]}
            pointerEvents={hideHeader ? 'none' : 'auto'}
        >
            <Animated.View style={[styles.innerContainer]}>
                <View style={styles.center} >
                    {!(title && title !== '') && (
                        <View style={styles.logo}>
                            {isLoggedIn ? (
                                <SVGLocal name="boomerang" width="100%" height="100%" />
                            ) : (
                                <SVGLocal name="logo_swood" width="100%" height="100%" />
                            )}
                        </View>
                    )}
                </View>
                <View style={styles.left}>
                    {previous && !gotLeftNull &&
                        (<Pressable style={styles.buttonIcon} onPress={navigation.goBack}>
                            <BackButton />
                        </Pressable>)
                    }
                    {!previous && isLoggedIn && (
                        <Pressable style={styles.buttonIcon} onPress={onUserPress}>
                            <ProfileButton />
                        </Pressable>
                    )}
                    {gotLeft && (
                        <Pressable style={styles.buttonIcon} onPress={navigation.goBack}>
                            <CloseButton />
                        </Pressable>
                    )}
                    {title && title !== '' && (
                        <View style={styles.title}>
                            <Title>{title}</Title>
                        </View>
                    )}
                </View>
                <View style={styles.right}>
                    {gotRight && (
                        <Pressable style={styles.buttonIcon} onPress={navigation.goBack}>
                            <CloseButton />
                        </Pressable>
                    )}
                </View>
            </Animated.View>
        </View>
    );
};

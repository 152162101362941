import { StyleSheet, Platform } from 'react-native';
import { colors, fonts, metrics } from '_theme';


export const getStyles = ({ size, design, disabled, color }) => {
    let styles = stylesBase;
    let sizeStyle = {}
    let colorStyle = {}
    switch (size) {
        case 'l':
            sizeStyle = stylesSizeL
            break;
        case 'm':
            sizeStyle = stylesSizeM
            break;
        case 's':
            sizeStyle = stylesSizeS
            break;
        default:
            sizeStyle = stylesSizeM
            break;
    }
    switch (design) {
        case 'grey':
            colorStyle = stylesDesignGrey
            break;
        case 'soft':
            colorStyle = stylesDesignSoft
            break;
        case 'discret':
            colorStyle = stylesDesignDiscret
            break;
        default:
            colorStyle = stylesDesignDefault
            break;
    }

    let mergedStyles = {}
    Object.keys(styles).forEach((key) => {
        mergedStyles[key] = {
            ...(styles[key] ? styles[key] : {}),
            ...(sizeStyle[key] ? sizeStyle[key] : {}),
            ...(colorStyle[key] ? colorStyle[key] : {}),
            ...(disabled && stylesDisabled[key] ? stylesDisabled[key] : {})
        }
    })

    return mergedStyles;
};

const stylesBase = {
    container: {
        borderRadius: metrics.borderRadius.full,
        borderWidth: metrics.borderWidth.m,
        flexDirection: 'row',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: "center"
    },
    before: {
    },
    textContainer: {
    },
    text: {
    },
    after: {
    },
};

const stylesDisabled = {
    container: {
        opacity: 0.2
    },
};

const stylesSizeL = {
    container: {
        paddingVertical: metrics.spacing.inset.l,
        paddingHorizontal: metrics.spacing.inset.l * 3,
        marginVertical: metrics.spacing.margin.l
    },
    before: {
        height: fonts.fontStyles.button.l.fontSize,
        width: fonts.fontStyles.button.l.fontSize,
    },
    textContainer: {

    },
    text: {
        textTransform: 'uppercase',
        ...fonts.fontStyles.button.l
    },
    after: {
        height: fonts.fontStyles.button.l.fontSize,
        width: fonts.fontStyles.button.l.fontSize,
    },
};
const stylesSizeM = {
    container: {
        paddingVertical: metrics.spacing.inset.m,
        paddingHorizontal: metrics.spacing.inset.m * 2,
        marginVertical: metrics.spacing.margin.m
    },
    before: {
        height: fonts.fontStyles.button.m.fontSize,
        width: fonts.fontStyles.button.m.fontSize,
    },
    textContainer: {
        marginHorizontal: metrics.spacing.inset.m
    },
    text: {
        textTransform: 'uppercase',
        ...fonts.fontStyles.button.m
    },
    after: {
        height: fonts.fontStyles.button.m.fontSize,
        width: fonts.fontStyles.button.m.fontSize,
    },
};
const stylesSizeS = {
    container: {
        paddingVertical: metrics.spacing.inset.s / 2,
        paddingHorizontal: metrics.spacing.inset.s * 4,
        marginVertical: metrics.spacing.margin.s / 2
    },
    before: {
        height: fonts.fontStyles.button.s.fontSize,
        width: fonts.fontStyles.button.s.fontSize
    },
    textContainer: {

    },
    text: {
        ...fonts.fontStyles.button.s
    },
    after: {
        height: fonts.fontStyles.button.s.fontSize,
        width: fonts.fontStyles.button.s.fontSize
    },
};

const stylesDesignDefault = {
    container: {
        borderColor: colors.pink_swood,
        backgroundColor: colors.pink_swood
    },
    before: {

    },
    textContainer: {

    },
    text: {
        color: colors.white_swood
    },
    after: {

    },
};
const stylesDesignGrey = {
    container: {
        borderColor: colors.black_swood,
        backgroundColor: colors.black_swood
    },
    before: {

    },
    textContainer: {

    },
    text: {
        color: colors.white_swood
    },
    after: {

    },
};
const stylesDesignSoft = {
    container: {
        borderColor: colors.black_swood,
    },
    before: {

    },
    textContainer: {

    },
    text: {
        color: colors.black_swood
    },
    after: {

    },
};
const stylesDesignDiscret = {
    container: {
        borderColor: 'transparent',
    },
    before: {

    },
    textContainer: {

    },
    text: {
        color: colors.black_33
    },
    after: {

    },
};
import React from 'react';
import { StyleSheet, View, Platform, Pressable } from 'react-native';
import { colors } from '_theme';
import { Paragraph } from '_components';

export const ProgressComponent = ({
  style,
  steps = [],
  onChange = () => { },
  value = 0,
  display = true,
  otherProps
}) => {
  if (display === false) {
    return null;
  }
  return (
    <View style={[styles.container, style]} {...otherProps}>
      <View style={styles.frame}>
        {steps.map((item, index) => {
          let isSelected = item.value === value
          return (
            <Pressable
              key={index + '_' + item.value}
              style={styles.step}
              onPress={() => { onChange(item) }}
            >
              <View
                style={[styles.bar, !isSelected ? styles.barInactive : {}]}
              />
              <Paragraph
                style={[styles.stepText, , !isSelected ? styles.stepTextInactive : {}]}
              >
                {item.label}
              </Paragraph>
            </Pressable>
          )
        })}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'stretch',
    marginRight: 12,
    marginLeft: 12,
    marginBottom: 10,
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'stretch',
  },
  frame: {
    width: "100%",
    maxWidth: 450,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'stretch',
  },
  step: {
    flex: 1,
    marginRight: 4,
    marginLeft: 4,
    flexDirection: 'column',
    alignItems: 'center'
  },
  bar: {
    backgroundColor: colors.green_swood,
    height: 6,
    marginBottom: 5,
    alignSelf: 'stretch',
    borderRadius: 12,
  },
  stepText: {
    color: colors.green_swood
  },
  stepTextInactive: {
    color: colors.green_swood_opace
  },
  barInactive: {
    backgroundColor: colors.green_swood_opace,
  },
});

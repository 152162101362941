import { colors } from "_theme/colors";

const sizes = {
    title: {
        l: 24,
        m: 18,
        s: 14,
        xs: 12
    },
    paragraph: {
        l: 14,
        m: 12,
        s: 10
    },
    button: {
        l: 24,
        m: 18,
        s: 14
    },
    input: {
        m: 16
    }
};

const fontFamillies = {
    primaryFont: {
        fontFamily: 'VolteRounded-Semibold',
    },
    secondaryFont: {
        fontFamily: 'VolteRounded-Semibold',
    },
    simpleFont: {
        fontFamily: 'VolteRounded-Semibold',
    },
}
const fontStyles = {

    title: {
        l: {
            ...fontFamillies.primaryFont,
            fontSize: sizes.title.l,
            color: colors.black,
        },
        m: {
            ...fontFamillies.primaryFont,
            fontSize: sizes.title.m,
            color: colors.black,
        },
        s: {
            ...fontFamillies.primaryFont,
            fontSize: sizes.title.s,
            color: colors.black,
        },
        xs: {
            ...fontFamillies.primaryFont,
            fontSize: sizes.title.xs,
            color: colors.black,
        }
    },
    paragraph: {
        l: {
            ...fontFamillies.primaryFont,
            fontSize: sizes.paragraph.l,
            color: colors.black,
        },
        m: {
            ...fontFamillies.primaryFont,
            fontSize: sizes.paragraph.m,
            color: colors.black,
        },
        s: {
            ...fontFamillies.primaryFont,
            fontSize: sizes.paragraph.s,
            color: colors.black,
        }
    },
    button: {
        l: {
            ...fontFamillies.primaryFont,
            fontSize: sizes.button.l,
            color: colors.black,
        },
        m: {
            ...fontFamillies.primaryFont,
            fontSize: sizes.button.m,
            color: colors.black,
        },
        s: {
            ...fontFamillies.primaryFont,
            fontSize: sizes.button.s,
            color: colors.black,
        }
    },
    input: {
        m: {
            ...fontFamillies.primaryFont,
            fontSize: sizes.title.m,
            color: colors.black,
        }
    }

}

export const fonts = {
    sizes,
    fontFamillies,
    fontStyles
}
import React, { useEffect, useRef } from 'react';
import {
  View,
  ScrollView,
  Platform,
  SafeAreaView,
  KeyboardAvoidingView,
  Dimensions,
} from 'react-native';
import { useDispatch } from 'react-redux';

import { StarComponent, ProgressComponent, ProfileHeader, BoxMulticolorShadow, Title, ToolTipPoint } from '_components';
import {
  StarDescription,
  AddCommentButton,
  CommentField,
  TagListComponentForEval,
  BottomButtons,
} from './EvaluationScreenUtilities';
import { styles } from './EvaluationScreenStyle';
import { getFeedback, updateFeedback } from '_services/fetchManager';
import { evaluationConfig } from './EvaluationConfig';
import {
  getCorrectTags,
  convertStringToArray,
} from '_services/utilitiesManager';
import { removeOneToDo } from '_redux/actions/userActions';
import { deleteToDo } from '_redux/actions/feedbackActions';


import Alert, { AlertRoot } from '_navigation/AlertCrossPlatform';

const windowWidth = Dimensions.get('window').width;
const evaluationSteps = Object.keys(evaluationConfig).map((key) => {
  let item = evaluationConfig[key]
  return { ...item, label: item.criteria }
})

function EvaluationScreen({ route, navigation }) {
  const [starValue, onChangeStarValue] = React.useState(0);
  const [isEditing, onChangeEditing] = React.useState(false);
  const [comment, onChangeComment] = React.useState('');
  const [selectedTags, onSetTags] = React.useState([]);
  const [feedback, onSetFeedback] = React.useState(undefined);
  const [currentStep, onSetStep] = React.useState(evaluationConfig.efficacity);
  const params = route.params;
  const dispatch = useDispatch();
  const scrollViewRef = useRef();

  useEffect(() => {
    if (params && params.id) {
      getFeedback({ id: params.id }).then((res) => {
        if (res.success === true) {
          const data = res.data;
          onSetFeedback(data);
          //TODO: revoir ces verifs si jamais tout est rempli
          if (data.perfRating === null) {
            onSetStep(evaluationConfig.efficacity);
          } else if (data.qualityRating === null) {
            onSetStep(evaluationConfig.quality);
          } else if (data.spiritRating === null) {
            onSetStep(evaluationConfig.spirit);
          }
        }
      });
    }
  }, [params]);

  const onNextPress = () => {
    var body = {};
    var nextStep;
    if (currentStep.value === evaluationConfig.efficacity.value) {
      body.perfRating = starValue;
      feedback.perfRating = starValue;
      body.perfComment = comment;
      feedback.perfComment = comment;
      body.perfTags = selectedTags.toString();
      feedback.perfTags = selectedTags.toString();
      nextStep = evaluationConfig.quality;
    } else if (currentStep.value === evaluationConfig.quality.value) {
      body.qualityRating = starValue;
      feedback.qualityRating = starValue;
      body.qualityComment = comment;
      feedback.qualityComment = comment;
      body.qualityTags = selectedTags.toString();
      feedback.qualityTags = selectedTags.toString();
      nextStep = evaluationConfig.spirit;
    } else {
      body.spiritRating = starValue;
      body.spiritComment = comment;
      body.spiritTags = selectedTags.toString();
    }
    var id = params.id;
    updateFeedback({ id, body }).then((res) => {
      if (res.success) {
        onChangeStarValue(0);
        onChangeComment('');
        onSetTags([]);
        onChangeEditing(false);
        if (nextStep) {
          onSetStep(nextStep);
        } else {
          dispatch(removeOneToDo());
          dispatch(deleteToDo(feedback.id));
          navigation.replace('ThankYou');
        }
      }
    });
  };

  const onBackPress = () => {
    var previousStep = currentStep;
    if (currentStep === evaluationConfig.quality) {
      previousStep = evaluationConfig.efficacity;
    } else if (currentStep === evaluationConfig.spirit) {
      previousStep = evaluationConfig.quality;
    }
    onChangeEditing(false);
    onSetStep(previousStep);
    if (currentStep === evaluationConfig.quality) {
      onChangeStarValue(feedback.perfRating);
      onChangeComment(feedback.perfComment);
      onSetTags(convertStringToArray(feedback.perfTags));
    } else if (currentStep === evaluationConfig.spirit) {
      onChangeStarValue(feedback.qualityRating);
      onChangeComment(feedback.qualityComment);
      onSetTags(convertStringToArray(feedback.qualityTags));
    }
  };
  const onStepPress = (stepObject) => {
    let isStepBefore = stepObject.value < currentStep.value
    // commented because going to next step cause UX issue ( are changes saved ? like on next step ?)
    // let currentStepIsSaved = feedback && feedback[currentStep.feedbackKeyBase + 'Rating']
    // let isStepAfter = stepObject.value > currentStep.value
    let canChange = isStepBefore // || (isStepAfter && currentStepIsSaved)
    if (canChange) {
      onChangeEditing(false);
      onSetStep(stepObject);
      onChangeStarValue(feedback[stepObject.feedbackKeyBase + 'Rating']);
      onChangeComment(feedback[stepObject.feedbackKeyBase + 'Comment']);
      onSetTags(convertStringToArray(feedback[stepObject.feedbackKeyBase + 'Tags']));
    }
  }
  const onStarPress = (value) => {
    onChangeStarValue(value);
    onSetTags([]);
  };

  const onAddCommentPress = () => {
    onChangeEditing(true);
  };

  const onCancelCommentPress = () => {
    onChangeEditing(false);
    onChangeComment('');
  };

  const onSendCommentPress = () => {
    onChangeEditing(false);
  };

  const onTagPress = (tag) => {
    if (selectedTags.includes(tag.text)) {
      onSetTags(selectedTags.filter((item) => item !== tag.text));
    } else {
      onSetTags(selectedTags.concat(tag.text));
    }
  };

  const onCommentFieldFocus = () => {
    scrollViewRef.current.scrollToEnd({ animated: true });
  };
  const showStepDetail = () => {
    let infos = currentStep.details
    Alert.alert(
      null,
      infos,
      [
        {
          text: 'OK',
          style: 'cancel',
        },
      ],
      { cancelable: true },
    );
  }

  if (feedback) {
    return (
      <SafeAreaView style={styles.container}>
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          style={styles.keyboardAvoiding}>
          <ScrollView
            style={styles.scroll}
            contentContainerStyle={[styles.scrollInner]}
            ref={scrollViewRef}>
            <RenderFrame style={styles.evalStepFrame}>
              <ProfileHeader
                mission={feedback.mission}
                user={feedback.mission.swoody}
              />
              <View>
                <Title style={[styles.textJobQuestion, styles.textQuestion]} onPress={showStepDetail}>
                  {"Comment évaluez-vous "}
                  {currentStep.question}
                  {" ?"}
                  <ToolTipPoint />
                </Title>
              </View>
              <StarComponent value={starValue} onPress={onStarPress} />
              <StarDescription starValue={starValue} />
              <TagListComponentForEval
                starValue={starValue}
                tagList={getCorrectTags({ starValue, config: currentStep })}
                tagSelectedList={selectedTags}
                onTagPress={onTagPress}
              />
              <AddCommentButton
                starValue={starValue}
                onAddCommentPress={onAddCommentPress}
                isEditing={isEditing}
                comment={comment}
              />
              <View style={styles.commentFieldContainer}>
                <CommentField
                  starValue={starValue}
                  isEditing={isEditing}
                  comment={comment}
                  onCommentFieldFocus={onCommentFieldFocus}
                  onCancelCommentPress={onCancelCommentPress}
                  onSendCommentPress={onSendCommentPress}
                  onEditCommentPress={onAddCommentPress}
                  onWebTextChange={(text) => onChangeComment(text)}
                />
              </View>
            </RenderFrame>
          </ScrollView>
        </KeyboardAvoidingView>
        <BottomButtons
          currentStep={currentStep}
          onNextPress={onNextPress}
          onBackPress={onBackPress}
          starValue={starValue}
          comment={comment}
          selectedTags={selectedTags}
        />
        <View style={[styles.progressBarContainer]}>
          <ProgressComponent
            style={[styles.progressBar]}
            steps={evaluationSteps}
            value={currentStep.value}
            onChange={(stepObject) => {
              onStepPress(stepObject)
            }}
          />
        </View>
        <AlertRoot />
      </SafeAreaView>
    );
  } else {
    return null;
  }
}
const RenderFrame = (props) => {
  if (windowWidth > 500) {
    return <BoxMulticolorShadow {...props} />
  } else {
    return <View {...props} />
  }
}
export default EvaluationScreen;

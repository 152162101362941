import React from 'react';
import { StyleSheet, Platform, View, Text } from 'react-native';

function DummyScreen({ navigation }) {
  return (
    <View style={styles.linearGradient}>
      <Text style={styles.dummyText}>
        Merci d'avoir rempli le questionnaire
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  linearGradient: {
    flex: 1,
    width: '100%',
    height: Platform.OS === 'web' ? '100vh' : '100%',
    margin: 0,
    padding: 0,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
  },
  dummyText: {
    color: 'black',
    fontSize: 50,
    fontFamily: 'VolteRounded-Semibold',
  },
});

export default DummyScreen;

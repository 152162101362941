export const getCommentByStar = ({starValue}) => {
  switch (starValue) {
    case 1:
      return 'Inacceptable';
    case 2:
      return 'Insatisfaisant';
    case 3:
      return 'Moyen';
    case 4:
      return 'Satisfaisant';
    case 5:
      return 'Excellent';
    default:
      return 'Commentaire';
  }
};

export const evaluationConfig = {
  efficacity: {
    value: 0,
    question: 'son efficacité',
    details: "L'efficacité d’un travail peut se mesurer à la réactivité, l'organisation, l'autonomie...",
    criteria: 'Efficacité',
    feedbackKeyBase: 'perf'
  },
  quality: {
    value: 1,
    question: 'la qualité de son travail',
    details: 'La qualité d’un travail peut se mesurer à la propreté des livrables, à la rigueur au travail...',
    criteria: 'Qualité',
    feedbackKeyBase: 'quality'
  },
  spirit: {
    value: 2,
    question: "son état d'esprit",
    details: "L'état d'esprit peut se mesurer à la communication, l'esprit d’équipe, la motivation...",
    criteria: "Etat d'esprit",
    feedbackKeyBase: 'spirit'
  },
};

export const tags = [
  {
    id: 0,
    text: 'Délais',
    stars: [1, 2, 3],
    criteria: [evaluationConfig.efficacity],
  },
  {
    id: 1,
    text: 'Communication',
    stars: [1, 2, 3, 4, 5],
    criteria: [evaluationConfig.spirit],
  },
  {
    id: 2,
    text: 'Proactif(ve)',
    stars: [4, 5],
    criteria: [evaluationConfig.spirit],
  },
  {
    id: 3,
    text: 'Créatif(ve)',
    stars: [4, 5],
    criteria: [evaluationConfig.quality, evaluationConfig.spirit],
  },
  {
    id: 4,
    text: 'Hatif(ve)',
    stars: [1, 2, 3],
    criteria: [evaluationConfig.quality, evaluationConfig.spirit],
  },
  {
    id: 5,
    text: 'Organisation',
    stars: [1, 2, 3, 4, 5],
    criteria: [evaluationConfig.efficacity, evaluationConfig.spirit],
  },
  {
    id: 6,
    text: "Esprit d'analyse",
    stars: [1, 2, 3, 4, 5],
    criteria: [evaluationConfig.quality, evaluationConfig.spirit],
  },
  {
    id: 7,
    text: 'Pertinence',
    stars: [1, 2, 3, 4, 5],
    criteria: [evaluationConfig.efficacity, evaluationConfig.quality],
  },
  {
    id: 8,
    text: 'Intégration',
    stars: [1, 2, 3],
    criteria: [evaluationConfig.spirit],
  },
  {
    id: 9,
    text: 'Relationnel',
    stars: [1, 2, 3, 4, 5],
    criteria: [evaluationConfig.spirit],
  },
  {
    id: 10,
    text: 'Disponibilité',
    stars: [1, 2, 3, 4, 5],
    criteria: [evaluationConfig.efficacity, evaluationConfig.spirit],
  },
  {
    id: 11,
    text: 'Productivité',
    stars: [1, 2, 3, 4, 5],
    criteria: [evaluationConfig.efficacity],
  },
  {
    id: 12,
    text: 'Incomplet',
    stars: [1, 2, 3],
    criteria: [evaluationConfig.quality],
  },
  {
    id: 13,
    text: 'Innovant(e)',
    stars: [4, 5],
    criteria: [evaluationConfig.quality],
  },
  {
    id: 14,
    text: 'Compréhension',
    stars: [1, 2, 3, 4, 5],
    criteria: [evaluationConfig.quality, evaluationConfig.efficacity],
  },
  {
    id: 15,
    text: 'Rigueur',
    stars: [1, 2, 3, 4, 5],
    criteria: [evaluationConfig.quality],
  },
  {
    id: 16,
    text: 'En progression',
    stars: [3, 4, 5],
    criteria: [
      evaluationConfig.efficacity,
      evaluationConfig.quality,
      evaluationConfig.spirit,
    ],
  },
  {
    id: 17,
    text: 'Autonomie',
    stars: [1, 2, 3, 4, 5],
    criteria: [evaluationConfig.efficacity, evaluationConfig.spirit],
  },
  {
    id: 18,
    text: 'Fiable',
    stars: [4, 5],
    criteria: [evaluationConfig.efficacity, evaluationConfig.spirit],
  },
  {
    id: 19,
    text: 'Je veux être contacté(e)',
    stars: [1, 2, 3],
    criteria: [
      evaluationConfig.efficacity,
      evaluationConfig.quality,
      evaluationConfig.spirit,
    ],
  },
];

import React from 'react';
import { View } from 'react-native';

import { SVGLocal } from '_components/SVGLocal'

import { styles } from './styles'

export const RectangleGradient = ({ start }) => {

  return (
    <View style={start ? styles.rct1 : styles.rct2}>
      <SVGLocal name={'rectangle_gradient'} alt="RctGradient" width={'50%'} height={5} />
    </View>
  )
}

import { colors } from "_theme/colors";

const identityDiagonal = {
    start: { x: 0, y: 1 },
    end: { x: 1, y: 0 },
    locations: [0, 1],
    colors: [
        colors.pink_swood,
        colors.green_swood,
    ],
}
const identityHorizontal = {
    start: { x: 0, y: 0 },
    end: { x: 1, y: 0 },
    locations: [0, 1],
    colors: [
        colors.green_swood,
        colors.pink_swood,
    ],
}

const whiteToTransparentVertical = {
    start: { x: 0, y: 0 },
    end: { x: 0, y: 1 },
    locations: [0, 0.4, 0.6, 1],
    colors: [
        colors.white_swood,
        colors.white_swood + 'ee',
        colors.white_swood + 'cc',
        colors.white_swood + '00',
    ],
}



export const gradients = {
    identityDiagonal,
    identityHorizontal,
    whiteToTransparentVertical,
}
import { StyleSheet, Platform } from 'react-native';
import { colors } from '_theme';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    margin: 0,
    padding: 0,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    backgroundColor: 'white',
    paddingTop: Platform.OS === 'ios' ? 20 : 0,
  },
  cardContainer: {
    backgroundColor: 'white',
    marginTop: 30,
    marginRight: 16,
    marginLeft: 16,
    padding: 16,
    paddingBottom: 20,
    borderRadius: 8,
    flex: 1,
    shadowColor: colors.black_swood,
    shadowOffset: { width: 1, height: 6 },
    shadowOpacity: 0.5,
    shadowRadius: 8,
    elevation: 3,
    justifyContent: 'flex-start',
  },
  buttonSignOut: {
    height: 40,
    marginBottom: 30,
    marginTop: 30,
    marginRight: 16,
    marginLeft: 16,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: colors.grey_66,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    fontSize: 14,
    fontFamily: 'VolteRounded-Semibold',
    color: colors.grey_66,
  },
  pictureView: {
    width: 110,
    height: 110,
    backgroundColor: colors.pink_swood,
    borderRadius: 60,
    marginTop: 20,
    overflow: 'hidden',
    alignSelf: 'center',
  },
  pictureFace: {
    flex: 1,
    resizeMode: 'contain',
  },
  textTitle: {
    fontSize: 10,
    fontFamily: 'VolteRounded-Semibold',
    color: colors.black_swood,
    marginBottom: 8,
  },
  textName: {
    color: colors.black_33,
    textAlign: 'center',
    marginTop: 16,
  },
  textDate: {
    color: colors.grey_66,
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 30,
  },
  textData: {
    color: colors.black_33,
  },
  separator: {
    marginVertical: 16,
    height: 1,
    backgroundColor: colors.grey_BF,
  },
});

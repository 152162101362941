import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'


import { LoadingFull } from '_components';
import { store, persistor } from '_redux/store';
import { RootNavigator } from '_navigation'

export const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={<LoadingFull />} persistor={persistor}>
        <RootNavigator />
      </PersistGate>
    </Provider>
  );
}

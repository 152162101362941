import React from 'react';
import { View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import { styles } from './styles'
import { gradients } from '_theme'

export const BoxMulticolorShadow = ({ style, children, ...otherProps }) => {
    // TODO : find a way to render colored shadows on android
    return (
        <View style={[styles.container, style]} {...otherProps}>
            <View style={styles.pinkShadow} />
            <View style={styles.greenShadow} />
            <LinearGradient
                {...{ ...gradients.identityHorizontal, }}
                style={styles.linearGradient}
            />
            <View style={styles.innerBox} />
            {children}
        </View>
    );
}
export const SET_LIST_TO_VALIDATE = 'SET_LIST_TO_VALIDATE';
export const SET_LIST_TO_DO = 'SET_LIST_TO_DO';
export const DELETE_TO_DO = 'DELETE_TO_DO';
export const DELETE_TO_VALIDATE = 'DELETE_TO_VALIDATE';

export const setListToDo = (list) => ({
  type: SET_LIST_TO_DO,
  data: list,
});

export const setListToValidate = (list) => ({
  type: SET_LIST_TO_VALIDATE,
  data: list,
});

export const deleteToDo = (id) => ({
  type: DELETE_TO_DO,
  data: id,
});

export const deleteToValidate = (id) => ({
  type: DELETE_TO_VALIDATE,
  data: id,
});

import React, { useEffect } from 'react';
import { View, FlatList } from 'react-native';
import { useSelector } from 'react-redux';
//
import { getFeedbackByUser } from '_services/fetchManager';
//
import { ReviewComponent, ProfileHeader } from '_components';
import { styles } from './SwoodyScreenStyles';

function SwoodyScreen({ route, navigation }) {
  const user = useSelector((state) => state.user.user);
  const [reviews, onSetReviews] = React.useState([]);
  const swoody = route.params.swoody;

  useEffect(() => {
    if (user === undefined) {
      navigation.navigate('Login');
    } else {
      getFeedbackByUser({ token: user.token, id: swoody.id }).then((res) => {
        if (res.success === true) {
          onSetReviews(res.data);
        }
      });
    }
  }, [navigation, user, swoody]);

  return (
    <View style={styles.container}>
      <View style={styles.profileContainer}>
        <ProfileHeader user={swoody} mission={swoody.missions[0]} />
      </View>
      <FlatList
        style={styles.list}
        data={reviews}
        renderItem={(item) => (
          <ReviewComponent mode="small" feedback={item.item} />
        )}
        keyExtractor={(item) => item.linkToken}
      />
    </View>
  );
}

export default SwoodyScreen;

import { StyleSheet, Platform } from 'react-native';
import { colors } from '_theme';
import React from 'react';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: Platform.OS === 'web' ? '100vh' : '100%',
    margin: 0,
    padding: 0,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardContainer: {
    backgroundColor: 'white',
    marginTop: 30,
    marginRight: 16,
    marginLeft: 16,
    paddingBottom: 20,
    borderRadius: 8,
    //flex: 1,
    shadowColor: colors.black_swood,
    shadowOffset: { width: 1, height: 6 },
    shadowOpacity: 0.5,
    shadowRadius: 8,
    elevation: 3,
  },
  cardTitleContainer: {
    height: 30,
    flexDirection: 'row',
    marginTop: 16,
    marginLeft: 16,
    marginRight: 16,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardRatingContainer: {
    height: 65,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'stretch',
    marginTop: 16,
    marginLeft: 16,
    marginRight: 16,
  },
  cardRatingView: {
    flex: 1,
    backgroundColor: colors.grey_light,
    marginLeft: 8,
    marginRight: 8,
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  textCritera: {
    color: 'black',
    fontSize: 14,
    fontFamily: 'VolteRounded-Semibold',
    marginLeft: 16,
    marginRight: 16,
    marginTop: 16,
    marginBottom: 8,
  },
  textRating: {
    fontSize: 12,
    fontFamily: 'VolteRounded-Semibold',
    color: colors.black_swood,
    marginTop: 5,
    marginBottom: 5,
  },
  textComment: {
    color: colors.grey_66,
    fontSize: 12,
    fontFamily: 'VolteRounded-Semibold',
    marginLeft: 16,
    marginRight: 16,
  },
  textTitle: {
    fontSize: 16,
    fontFamily: 'VolteRounded-Semibold',
    color: colors.black_33,
  },
  buttonConnect: {
    height: 50,
    width: '95%',
    backgroundColor: colors.pink_swood,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 100,
    alignSelf: 'center',
    marginBottom: 40,
    marginTop: 30,
  },
  buttonText: {
    color: 'white',
    fontSize: 18,
    fontFamily: 'VolteRounded-Semibold',
  },
});

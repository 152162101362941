import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { View, Text, TouchableOpacity, Image } from 'react-native';

import { styles } from './ProfileScreenStyle';
import { logOutUser } from '_redux/actions/userActions';
import { Title, Paragraph } from '_components'
import { getMonthYearFromTime } from '_services/utilitiesManager'

function ProfileScreen({ navigation }) {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  const onExitPress = () => {
    dispatch(logOutUser());
    navigation.navigate('Login');
  };

  return (
    <View style={styles.container}>
      <View style={styles.cardContainer}>
        <RenderUserHeader {...{ user }} />
        <RenderSection title={'Practice Leader'} text={user?.pl?.name} />
        <RenderSection title={'Responsable RH'} text={user?.rh?.name} />
      </View>
      <TouchableOpacity style={styles.buttonSignOut} onPress={onExitPress}>
        <Text style={styles.buttonText}>Me déconnecter</Text>
      </TouchableOpacity>
    </View>
  );
}


const RenderUserHeader = ({ user }) => {
  let userStartDate = user?.startDate
  let getStartDate = () => {
    if (userStartDate) {
      return (<Text>{' depuis '}{getMonthYearFromTime({ time: userStartDate })}</Text>)
    } else {
      return null
    }
  }
  return (
    <View>
      <View style={styles.pictureView}>
        <Image
          style={styles.pictureFace}
          source={{
            uri: user.pictureURL,
          }}
          coverMode={'contains'}
        />
      </View>
      <Title size="s" style={styles.textName}>{user.name}</Title>
      <Paragraph size="m" style={styles.textDate}>
        {'Chez SwoodPartners'}
        {getStartDate()}
      </Paragraph>
    </View>
  )
}


const RenderSection = ({ title, text }) => {
  if (!text) { return null }
  return (
    <View>
      <Title size="s" style={styles.textTitle}>{title}</Title>
      <Paragraph size="m" style={styles.textData}>
        {text}
      </Paragraph>
      <View style={styles.separator} />
    </View>
  )

}
export default ProfileScreen;

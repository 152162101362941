import { StyleSheet } from 'react-native';

import { metrics, colors } from '_theme';

export const styles = StyleSheet.create({
    container: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: colors.white_swood,
    },
    containerWithShadow: {
        shadowColor: colors.black_swood,
        shadowOffset: { width: 1, height: 6 },
        shadowOpacity: 0.5,
        shadowRadius: 8,
        elevation: 15,
    },
    innerContainer: {
        height: 66,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: metrics.spacing.inset.m,
        paddingVertical: metrics.spacing.inset.m,
        width: '100%',
        maxWidth: 1100,
    },
    innerContainerSmall: {
        //height: 66,
    },
    left: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    right: {
        flex: 1,
        flexDirection: 'row-reverse',
        alignItems: 'center',
    },
    center: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        //margin: metrics.spacing.inset.s,
    },
    logo: {
        height: "60%",
        width: "100%",
        maxWidth: 180,
    },
    title: {
        marginHorizontal: metrics.spacing.inset.m
    },
});

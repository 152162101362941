import React from 'react';
import {
  View,
  Text,
  StyleSheet,
  Platform,
  TouchableOpacity,
} from 'react-native';
//
import {
  getMonthYearFromTime,
  getAverage,
  convertStringToTags,
  convertStringToArray,
} from '_services/utilitiesManager';
//
import { RatingStarComponent, TagListComponent, SVGLocal } from '_components';
import { colors } from '_theme';
import Alert, { AlertRoot } from '_navigation/AlertCrossPlatform';

export const ReviewComponent = ({ feedback, mode, name, onCommentDelete = () => { } }) => {
  const [size, onChangeSize] = React.useState(mode);
  const [perfComment, onChangePerfComment] = React.useState(
    feedback.perfComment,
  );
  const [qualityComment, onChangeQualityComment] = React.useState(
    feedback.qualityComment,
  );
  const [spiritComment, onChangeSpiritComment] = React.useState(
    feedback.spiritComment,
  );

  const onCommentPress = () => {
    if (size === 'small') {
      onChangeSize('big');
    } else if (size === 'big') {
      onChangeSize('small');
    }
  };

  const onCommentDeletePress = ({ criteria }) => {
    if (Platform.OS === 'web') {
      onCommentDeleteConfirm({ criteria });
    } else {
      Alert.alert(
        "Suppression d'un commentaire",
        'Ce commentaire sera supprimé définitivement lorsque vous validerez cet avis. Voulez-vous continuer ?',
        [
          {
            text: 'Supprimer',
            onPress: () => onCommentDeleteConfirm({ criteria }),
          },
          {
            text: 'Annuler',
            style: 'cancel',
          },
        ],
        { cancelable: false },
      );
    }
  };

  const onCommentDeleteConfirm = ({ criteria }) => {
    if (criteria === 'efficacity') {
      onChangePerfComment('');
    }
    if (criteria === 'quality') {
      onChangeQualityComment('');
    }
    if (criteria === 'spirit') {
      onChangeSpiritComment('');
    }
    onCommentDelete({ criteria });
  };

  const TextUpgrade = ({ comment, tag }) => {
    if (comment !== undefined && comment !== null && comment !== '') {
      return <Text style={styles.textComment}>{comment}</Text>;
    } else if (tag === undefined || tag === null || tag === '') {
      return (
        <Text style={styles.textComment}>
          Aucunes informations fournies sur ce critère
        </Text>
      );
    }
    return null;
  };

  const ReviewDetail = () => {
    if (size === 'valid' || size === 'big') {
      return (
        <View>
          <Text style={styles.textCritera}>Efficacité</Text>
          <TextUpgrade comment={perfComment} tag={feedback.perfTags} />
          <RenderDelete value={perfComment} criteria={'efficacity'} />
          <TagListComponent
            onTagPress={() => { }}
            tagList={convertStringToTags(feedback.perfTags)}
            tagSelectedList={convertStringToArray(feedback.perfTags)}
            center={false}
          />
          <Text style={styles.textCritera}>Qualité</Text>
          <TextUpgrade comment={qualityComment} tag={feedback.qualityTags} />
          <RenderDelete value={qualityComment} criteria={'quality'} />
          <TagListComponent
            onTagPress={() => { }}
            tagList={convertStringToTags(feedback.qualityTags)}
            tagSelectedList={convertStringToArray(feedback.qualityTags)}
            center={false}
          />
          <Text style={styles.textCritera}>Etat d'esprit</Text>
          <TextUpgrade comment={spiritComment} tag={feedback.spiritTags} />
          <RenderDelete value={spiritComment} criteria={'spirit'} />
          <TagListComponent
            onTagPress={() => { }}
            tagList={convertStringToTags(feedback.spiritTags)}
            tagSelectedList={convertStringToArray(feedback.spiritTags)}
            center={false}
          />
          <RenderBottom />
        </View>
      );
    } else {
      return (
        <TouchableOpacity
          style={styles.seeCommentButton}
          onPress={onCommentPress}>
          <Text style={styles.seeCommentText}>Voir le détail</Text>
          <SVGLocal name={'bottom_arrow'} />
        </TouchableOpacity>
      );
    }
  };

  const RenderDelete = ({ value, criteria }) => {
    if (size === 'valid' && value !== '') {
      return (
        <TouchableOpacity
          style={styles.deleteCommentButton}
          onPress={() => onCommentDeletePress({ criteria })}>
          <SVGLocal name={'trash_can'} />
        </TouchableOpacity>
      );
    }
    return null;
  };

  const RenderBottom = () => {
    if (size === 'big') {
      return (
        <TouchableOpacity
          style={styles.seeCommentButton}
          onPress={onCommentPress}>
          <Text style={styles.seeCommentText}>Fermer</Text>
          <SVGLocal name={'top_arrow'} />
        </TouchableOpacity>
      );
    }
    return null;
  };

  const RenderTextTop = () => {
    if (name) {
      return (
        <Text style={styles.textSubtitle}>
          {getMonthYearFromTime({ time: feedback.createdAt })}
        </Text>
      );
    }
    return null;
  };

  return (
    <View style={styles.cardContainer}>
      <View style={styles.cardTitleContainer}>
        <Text style={styles.textTitle}>
          {name ? name : getMonthYearFromTime({ time: feedback.createdAt })}
        </Text>
        <RatingStarComponent
          value={getAverage(
            feedback.perfRating,
            feedback.qualityRating,
            feedback.spiritRating,
          )}
          size={16}
        />
      </View>
      <RenderTextTop />
      <View style={styles.cardRatingContainer}>
        <View style={styles.cardRatingView}>
          <Text style={styles.textRating}>Efficacité</Text>
          <RatingStarComponent value={feedback.perfRating} size={14} />
        </View>
        <View style={styles.cardRatingView}>
          <Text style={styles.textRating}>Qualité</Text>
          <RatingStarComponent value={feedback.qualityRating} size={14} />
        </View>
        <View style={styles.cardRatingView}>
          <Text style={styles.textRating}>Etat d'esprit</Text>
          <RatingStarComponent value={feedback.spiritRating} size={14} />
        </View>
      </View>
      <ReviewDetail />
      <AlertRoot />
    </View>
  );
}


const styles = StyleSheet.create({
  cardContainer: {
    backgroundColor: 'white',
    marginTop: 25,
    marginRight: 16,
    marginLeft: 16,
    paddingBottom: 20,
    borderRadius: 8,
    marginBottom: 5,
    shadowColor: colors.black_swood,
    shadowOffset: { width: 1, height: 6 },
    shadowOpacity: 0.5,
    shadowRadius: 8,
    elevation: 3,
  },
  cardTitleContainer: {
    height: 30,
    flexDirection: 'row',
    marginTop: 16,
    marginLeft: 16,
    marginRight: 16,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardRatingContainer: {
    height: 65,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'stretch',
    marginTop: 16,
    marginLeft: 16,
    marginRight: 16,
  },
  cardRatingView: {
    flex: 1,
    backgroundColor: colors.grey_light,
    marginLeft: 8,
    marginRight: 8,
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  textCritera: {
    color: 'black',
    fontSize: 14,
    fontFamily: 'VolteRounded-Semibold',
    marginLeft: 16,
    marginRight: 16,
    marginTop: 16,
    marginBottom: 8,
  },
  textRating: {
    fontSize: 12,
    fontFamily: 'VolteRounded-Semibold',
    color: colors.black_swood,
    marginTop: 5,
    marginBottom: 5,
  },
  textComment: {
    color: colors.grey_66,
    fontSize: 12,
    fontFamily: 'VolteRounded-Semibold',
    marginLeft: 16,
    marginRight: 16,
  },
  textTitle: {
    fontSize: 16,
    fontFamily: 'VolteRounded-Semibold',
    color: colors.black_33,
  },
  textSubtitle: {
    fontSize: 14,
    fontFamily: 'VolteRounded-Semibold',
    color: colors.grey_66,
    marginLeft: 16,
  },
  buttonConnect: {
    height: 50,
    width: '95%',
    backgroundColor: colors.pink_swood,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 100,
    alignSelf: 'center',
    marginBottom: 40,
    marginTop: 30,
  },
  buttonText: {
    color: 'white',
    fontSize: 18,
    fontFamily: 'VolteRounded-Semibold',
  },
  seeCommentText: {
    fontSize: 12,
    fontFamily: 'VolteRounded-Semibold',
    color: colors.black_33,
    marginRight: 10,
  },
  seeCommentButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 15,
  },
  deleteCommentButton: {
    marginRight: 16,
    alignItems: 'flex-end',
  },
});

export const colors = {

  //blue
  blue_website: 'rgb(81,181,201)',
  //purple
  purple_website: 'rgb(131,24,80)',
  // green
  green_swood: 'rgb(21, 211, 180)', //#15D3B4
  green_swood_opace: 'rgba(21, 211, 180, 0.4)',
  // pink
  pink_swood: 'rgb(217, 3, 107)', //#D9036B
  pink_swood_opace: 'rgba(217, 3, 107, 0.2)',
  // white
  white_swood: '#ffffff',
  // black
  black: '#000000',
  black_swood: '#44546A',
  black_33: '#333333',
  // gray
  grey_light: '#F3F1EF',
  grey_66: '#666666',
  grey_F9: '#F9F9F9',
  grey_BF: '#BFBFBF',

  backdrop: 'rgba(108, 108, 108, 0.7)'
};

import React from 'react';
import { StyleSheet, View, Image, Text } from 'react-native';
import star_full from '_assets/pictures/star_full/star.png';
import { colors } from '_theme';

export const RatingStarComponent = ({ value, size }) => {
  return (
    <View style={styles.container}>
      <Text style={[styles.text, { fontSize: size }]}>{value}</Text>
      <Image source={star_full} style={{ height: size, width: size }} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontFamily: 'VolteRounded-Semibold',
    marginRight: 5,
    color: colors.black_swood,
  },
});

import * as React from "react";
import {
    StyleSheet,
    View,
    Button,
    Modal,
    Text,
    TouchableWithoutFeedback
} from "react-native";

import { ModalAlert } from '_components';

const AlertHandler = createAlertHandler();

function createAlertHandler() {
    // local state of alert handler
    let subscriber;
    let alerts = [];

    let setAlerts = (previousAlertsCallback) => {
        alerts = previousAlertsCallback(alerts);
        // notify alert root
        subscriber && subscriber(alerts);
    };

    function use() {
        // eslint-disable-next-line
        let [localAlerts, s] = React.useState(alerts);

        // subscribe to external changes
        // eslint-disable-next-line
        React.useEffect(() => {
            subscriber = s;
            return () => {
                subscriber = undefined;
            };
        }, [s]);

        // set callback
        return [localAlerts, setAlerts];
    }

    return {
        api: {
            alert: (title, message, buttons, options) => {
                setAlerts((prev) => [...prev, { title, message, buttons, options }]);
            }
        },
        use
    };
}

export function AlertRoot() {
    const [alerts, setAlerts] = AlertHandler.use();

    const onClose = (indexToRemove) => {
        setAlerts((prev) => prev.filter((_, i) => i !== indexToRemove));
    };

    return alerts.map(({ title, message, buttons, options }, alertIndex) => (
        <ModalAlert {...{ alertIndex, onClose, title, message, buttons, options }} />
    ));
}

export default AlertHandler.api;
import React, { useEffect } from 'react';
import { Platform, Pressable, Linking } from 'react-native';
import { NavigationContainer, getStateFromPath } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import { useSelector } from 'react-redux';

import { BackButton, CloseButton, LoadingFull, NavigationHeader } from '_components';
import { colors } from '_theme'

// SCREENS
import SplashScreen from 'react-native-splash-screen';
import LoginScreen from '_views/LoginScreen/LoginScreen';
import DummyScreen from '_views/DummyScreen';
import EvaluationScreen from '_views/EvaluationScreen/EvaluationScreen';
import ListScreen from '_views/ListScreen/ListScreen';
import ThankyouScreen from '_views/ThankyouScreen/ThankyouScreen';
import DashboardScreen from '_views/DashboardScreen/DashboardScreen';
import ReviewScreen from '_views/ReviewScreen/ReviewScreen';
import TeamScreen from '_views/TeamScreen/TeamScreen';
import EvalListScreen from '_views/EvalListScreen/EvalListScreen';
import SwoodyScreen from '_views/SwoodyScreen/SwoodyScreen';
import ProfileScreen from '_views/ProfileScreen/ProfileScreen';
import PasswordScreen from '_views/PasswordScreen/PasswordScreen';
import ResetScreen from '_views/ResetScreen/ResetScreen';


//screen options
import { screenOptions } from './screenOptions'


// create stack
const RootStack = createStackNavigator();

export const RootNavigator = () => {

    const user = useSelector((state) => state.user.user);
    const isLoggedIn = user?.token ? true : false;
    useEffect(() => {
        if (Platform.OS !== 'web') {
            SplashScreen.hide();
        }
    }, []);
    // construct 
    let linkingConfig = {
        prefixes: ['https://boomerang.swood.fr', 'https://www.boomerang.swood.fr', 'boomerang://', 'app://boomerang'],
        //config: {},
        async getInitialURL() {

            const url = await Linking.getInitialURL();
            // console.log('---> initial Url ? ', url)
            if (url != null) {
                return url;
            }

        },
        subscribe(listener) {

            // console.log('---> subscribe url reception')
            // First, you may want to do the default deep link handling
            const onReceiveURL = ({ url }) => {
                // console.log('---> recieved Url : ', url)
                return listener(url)
            };

            // Listen to incoming links from deep linking
            Linking.addEventListener('url', onReceiveURL);

            return () => {
                // Clean up the event listeners
                // console.log('---> unsubscribe url reception')
                Linking.removeEventListener('url', onReceiveURL);
            };
        },
        getStateFromPath(path, config) {
            let state = getStateFromPath(path, config)
            // Return a state object here
            // You can also reuse the default logic by importing `getStateFromPath` from `@react-navigation/native`

            // we want to prevent linked routes to not have back route button.
            // not loggedIn screens don't need a back route button.
            // if we are loggedIn and we got only one route, we define what to do
            if (state?.routes?.length === 1 && isLoggedIn) {
                let singleRouteName = state.routes[0].name
                switch (singleRouteName) {
                    case 'Login':
                    case 'Dashboard':
                        // we do nothing as they already are common root routes
                        break;
                    default:
                        // most of routes can happilly go back to Dashboard
                        state.routes.unshift({
                            name: 'Dashboard',
                            params: {}
                        })
                        break;
                }
            }
            return state
        },
        enabled: true
    }
    return (
        <NavigationContainer
            linking={linkingConfig}
            fallback={<LoadingFull />}
        >
            <RootStack.Navigator
                initialRouteName="Login"
                screenOptions={{
                    header: NavigationHeader,
                    animationEnabled: true,
                }}>
                {isLoggedIn ? (
                    <>
                        <RootStack.Screen
                            name="Dashboard"
                            component={DashboardScreen}
                            options={screenOptions.Dashboard}
                        />
                        <RootStack.Screen name="Test" component={DummyScreen} options={screenOptions.Test} />
                        <RootStack.Screen
                            name="evaluation"
                            component={EvaluationScreen}
                            options={screenOptions.evaluation}
                        />
                        <RootStack.Screen name="List" component={ListScreen} options={screenOptions.List} />
                        <RootStack.Screen
                            name="Team"
                            component={TeamScreen}
                            options={screenOptions.Team}
                        />
                        <RootStack.Screen name="Review" component={ReviewScreen} options={screenOptions.Review} />
                        <RootStack.Screen
                            name="Feedbacks"
                            component={EvalListScreen}
                            options={screenOptions.Feedbacks}
                        />
                        <RootStack.Screen
                            name="MonProfile"
                            component={ProfileScreen}
                            options={screenOptions.MonProfile}
                        />
                        <RootStack.Screen name="Password" component={PasswordScreen} options={screenOptions.Password} />
                        <RootStack.Screen
                            name="reset"
                            component={ResetScreen}
                            options={screenOptions.reset}
                        />
                        <RootStack.Screen
                            name="Swoody"
                            component={SwoodyScreen}
                            options={screenOptions.Swoody}
                        />
                        <RootStack.Screen
                            name="ThankYou"
                            component={ThankyouScreen}
                            options={screenOptions.ThankYou}
                        />
                    </>
                ) : (
                    <>
                        <RootStack.Screen
                            name="Login"
                            component={LoginScreen}
                            options={screenOptions.Login}
                        />
                        <RootStack.Screen
                            name="reset"
                            component={ResetScreen}
                            options={screenOptions.reset}
                        />
                        <RootStack.Screen
                            name="evaluation"
                            component={EvaluationScreen}
                            options={screenOptions.evaluation}
                        />
                        <RootStack.Screen
                            name="ThankYou"
                            component={ThankyouScreen}
                            options={screenOptions.ThankYou}
                        />
                    </>
                )}

            </RootStack.Navigator>
        </NavigationContainer>
    );
}
import React from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  TextInput,
  Platform,
} from 'react-native';
import { getCommentByStar } from '_assets/enum';
import { styles } from './EvaluationScreenStyle';
import add_comment_icon from '_assets/pictures/add_comment_icon/add_comment_icon.png';
import { TagListComponent, SVGLocal, ButtonStyled } from '_components';
import { evaluationConfig } from './EvaluationConfig';

export function StarDescription({ starValue }) {
  if (starValue > 0) {
    return (
      <View style={styles.viewDesc}>
        <Text style={styles.textDesc}>{getCommentByStar({ starValue })}</Text>
      </View>
    );
  }
  return null;
}

export function AddCommentButton({
  starValue,
  onAddCommentPress,
  isEditing,
  comment,
}) {
  if (isEditing || Platform.OS === 'web') {
    return null;
  }
  if (isEditing === false && starValue > 3 && comment === '') {
    return (
      <TouchableOpacity
        style={[styles.buttonCommentView, { marginTop: 30 }]}
        onPress={onAddCommentPress}>
        <Image
          style={styles.buttonCommentImage}
          source={add_comment_icon}
          coverMode={'contains'}
        />
        <Text style={styles.buttonCommentText}>Ajouter un commentaire</Text>
      </TouchableOpacity>
    );
  } else if (isEditing === false && starValue > 0 && comment === '') {
    return (
      <View style={styles.buttonCommentView2}>
        <Text style={styles.textWarning}>
          Vous n'êtes pas satisfait, quels sont les axes d'améliorations
          possibles ?
        </Text>
        <TouchableOpacity
          style={styles.buttonCommentView}
          onPress={onAddCommentPress}>
          <Image
            style={styles.buttonCommentImage}
            source={add_comment_icon}
            coverMode={'contains'}
          />
          <Text style={styles.buttonCommentText}>Ajouter un commentaire</Text>
        </TouchableOpacity>
      </View>
    );
  }
  return null;
}

export function CommentField({
  starValue,
  isEditing,
  comment = '',
  onCancelCommentPress,
  onSendCommentPress,
  onEditCommentPress,
  onWebTextChange,
  onCommentFieldFocus,
}) {
  if (Platform.OS === 'web' && starValue > 3) {
    return (
      <View style={styles.commentFieldView}>
        <TextInput
          placeholder="Votre commentaire"
          style={styles.commentFieldInput}
          onChangeText={onWebTextChange}
          multiline={true}
          value={comment}
          numberOfLines={5}
        />
      </View>
    );
  } else if (Platform.OS === 'web' && starValue > 0) {
    return (
      <View
        style={[
          styles.commentFieldView,
          { flexDirection: 'column', backgroundColor: 'transparent' },
        ]}>
        <Text style={styles.textWarning}>
          Vous n'êtes pas satisfait, quels sont les axes d'améliorations
          possibles ?
        </Text>
        <TextInput
          placeholder="Votre commentaire"
          style={styles.commentFieldInput}
          onChangeText={onWebTextChange}
          multiline={true}
          value={comment}
          numberOfLines={5}
        />
      </View>
    );
  } else if (isEditing === true) {
    return (
      <View style={styles.commentFieldView}>
        <TouchableOpacity
          style={styles.commentFieldIcon}
          onPress={onCancelCommentPress}>
          <SVGLocal name={'close'} />
        </TouchableOpacity>
        <TextInput
          placeholder="Votre commentaire"
          style={styles.commentFieldInput}
          onChangeText={(text) => onWebTextChange(text)}
          value={comment}
          onFocus={() => onCommentFieldFocus()}
          multiline
        />
        <TouchableOpacity
          style={styles.commentFieldIcon}
          onPress={() => onSendCommentPress()}>
          <SVGLocal name={'send'} />
        </TouchableOpacity>
      </View>
    );
  } else if (isEditing === false && comment !== '') {
    return (
      <View style={[styles.commentFieldView, { flexDirection: 'column' }]}>
        <Text style={styles.commentFieldText}>{comment}</Text>
        <View style={styles.commentFieldButtonView}>
          <TouchableOpacity onPress={onEditCommentPress}>
            <Text style={styles.commentFieldButtonText}>MODIFIER</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={onCancelCommentPress}>
            <Text style={styles.commentFieldButtonText}>SUPPRIMER</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
  return null;
}

export function TagListComponentForEval({
  starValue,
  tagList = [],
  tagSelectedList = [],
  onTagPress,
}) {
  if (starValue > 0) {
    return (
      <TagListComponent
        tagList={tagList}
        tagSelectedList={tagSelectedList}
        onTagPress={onTagPress}
      />
    );
  }
  return null;
}

export function BottomButtons({
  currentStep,
  onBackPress,
  onNextPress,
  starValue,
  comment,
  selectedTags,
}) {
  let isTagSelected = selectedTags && Array.isArray(selectedTags) && selectedTags.length > 0
  let isReady = (starValue > 3 && isTagSelected) || (starValue > 0 && isTagSelected && comment !== '')
  let gotPreviousStep = !(currentStep === evaluationConfig.efficacity)
  return (
    <View style={styles.bottomButtonsView}>
      {gotPreviousStep && (<ButtonStyled
        style={styles.buttons}
        size="m"
        design="discret"
        onPress={onBackPress}
        before={() => (<SVGLocal name={'back_arrow'} height="100%" width="100%" />)}>
        {"RETOUR"}
      </ButtonStyled>)}
      <ButtonStyled
        style={styles.buttons}
        size="m"
        onPress={onNextPress}
        disabled={!isReady}>
        SUIVANT
      </ButtonStyled>
    </View>
  );
}

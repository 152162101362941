
import { StyleSheet, Platform } from 'react-native';
import { colors, fonts, metrics, commonStyles } from '_theme';

let borderRadius = metrics.borderRadius.xl

export const styles = StyleSheet.create({
    container: {
        flex: 1,
        margin: 50,
        paddingVertical: metrics.spacing.margin.m / 2,
        paddingHorizontal: metrics.spacing.margin.m,
    },
    innerBox: {
        position: 'absolute',
        top: metrics.borderWidth.s,
        bottom: metrics.borderWidth.s,
        left: metrics.borderWidth.s,
        right: metrics.borderWidth.s,
        borderRadius: borderRadius - metrics.borderWidth.s,
        backgroundColor: colors.white_swood,
    },
    linearGradient: {
        position: 'absolute',
        borderRadius: borderRadius,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
    pinkShadow: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: metrics.spacing.margin.m,
        right: 0,
        backgroundColor: colors.pink_swood,
        borderRadius: borderRadius,
        shadowColor: colors.pink_swood,
        shadowOffset: { width: 4, height: 2 },
        shadowOpacity: 0.8,
        shadowRadius: 8,
        //elevation: 3,
    },
    greenShadow: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: metrics.spacing.margin.m,
        backgroundColor: colors.green_swood,
        borderRadius: borderRadius,
        shadowColor: colors.green_swood,
        shadowOffset: { width: -4, height: 2 },
        shadowOpacity: 0.8,
        shadowRadius: 8,
        //elevation: 3,
    }
});

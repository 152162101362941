
import { StyleSheet } from 'react-native';


export const styles = StyleSheet.create({
    upperFixView: {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
    },
    bottomFixView: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: 0,
    },
    rct1: {
        flexDirection: 'row',
    },
    rct2: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
});
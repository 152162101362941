import React from 'react';
import { Pressable, View } from 'react-native';


import { SVGLocal } from '_components'

import { styles } from './styles'

export const ToolTipPoint = ({ style, otherProps }) => {
    return (
        <View style={[styles.container, style]} {...otherProps}>
            <SVGLocal name="tooltip_icon" style={[styles.icon]} />
        </View>
    );
}
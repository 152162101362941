import { StyleSheet, Platform } from 'react-native';
import { colors, fonts, metrics, commonStyles } from '_theme';

export const styles = StyleSheet.create({
    modalInner: { justifyContent: "center", alignItems: "center" },
    backdrop: {
        backgroundColor: colors.backdrop
    },
    alert: {
        backgroundColor: "#fff",
        maxWidth: 400,
        minWidth: 300,
        ...commonStyles.shadow,
        margin: metrics.spacing.margin.m,
        padding: metrics.spacing.inset.m,
        borderRadius: metrics.borderRadius.m
    },
    title: {},
    message: { color: colors.grey_66, marginTop: metrics.spacing.margin.m },
    buttons: { flexDirection: "row", marginTop: metrics.spacing.margin.l, marginBottom: 0, },
    button: { marginVertical: 0, },
    fill: { flex: 1 },
    spacer: { width: metrics.spacing.margin.m }
});

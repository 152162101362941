import React from 'react';
import { StyleSheet, View, TouchableOpacity, Text } from 'react-native';
import { colors } from '_theme';

export const TagListComponent = ({
  tagList = [],
  tagSelectedList = [],
  onTagPress,
  center = true,
}) => {
  return (
    <View style={center === true ? styles.containerCenter : styles.container}>
      {(() => {
        const tab = [];
        for (let i = 0; i < tagList.length; i++) {
          if (tagSelectedList.includes(tagList[i].text)) {
            tab.push(
              <TouchableOpacity
                key={i}
                style={styles.buttonSelected}
                onPress={() => onTagPress(tagList[i])}>
                <Text style={[styles.text, styles.textSelected]}>
                  {tagList[i].text}
                </Text>
              </TouchableOpacity>,
            );
          } else {
            tab.push(
              <TouchableOpacity
                key={i}
                style={styles.buttonUnselected}
                onPress={() => onTagPress(tagList[i])}>
                <Text style={[styles.text, styles.textUnselected]}>
                  {tagList[i].text}
                </Text>
              </TouchableOpacity>,
            );
          }
        }
        return tab;
      })()}
    </View>
  );
}

const styles = StyleSheet.create({
  containerCenter: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginRight: 12,
    marginLeft: 12,
    marginTop: 5,
    flexWrap: 'wrap',
  },
  container: {
    flexDirection: 'row',
    marginRight: 12,
    marginLeft: 12,
    marginTop: 5,
    flexWrap: 'wrap',
  },
  buttonSelected: {
    backgroundColor: colors.black_swood,
    borderRadius: 20,
    margin: 5,
  },
  buttonUnselected: {
    backgroundColor: colors.grey_light,
    borderRadius: 20,
    margin: 5,
  },
  text: {
    margin: 8,
    fontFamily: 'VolteRounded-Semibold',
  },
  textSelected: {
    color: 'white',
  },
  textUnselected: {
    color: colors.black_swood,
  },
});

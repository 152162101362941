import * as React from "react";
import {
    StyleSheet,
    View,
    Button,
    Modal,
    Text,
    TouchableWithoutFeedback
} from "react-native";

import { ButtonStyled, Title, Paragraph } from '_components';

import { styles } from './styles'



export const ModalAlert = ({ alertIndex, onClose, title, message, buttons, options }) => {
    const backdrop = <View style={[StyleSheet.absoluteFill, styles.backdrop]} />;

    return (
        <Modal
            key={alertIndex}
            visible={true}
            animationType="none"
            transparent
            onRequestClose={
                options && options.cancelable ? () => onClose(alertIndex) : () => null
            }
        >
            <View style={[StyleSheet.absoluteFill, styles.modalInner]}>
                {options && options.cancelable ? (
                    <TouchableWithoutFeedback onPress={() => onClose(alertIndex)}>
                        {backdrop}
                    </TouchableWithoutFeedback>
                ) : (
                    backdrop
                )}
                <View style={styles.alert}>
                    <Title size="s" style={styles.title}>{title}</Title>
                    {message ? <Paragraph size="m" style={styles.message}>{message}</Paragraph> : null}
                    {buttons ? (
                        <View style={styles.buttons}>
                            {buttons.map(({ text, onPress, style }, buttonIndex) => {
                                const hasThree = buttons.length === 3;
                                const isFirst = buttonIndex === 0;
                                return (
                                    <React.Fragment key={buttonIndex}>
                                        {isFirst && !hasThree ? <View style={styles.fill} /> : null}
                                        {isFirst ? null : <View style={styles.spacer} />}
                                        <ButtonStyled
                                            style={styles.button}
                                            size={'s'}
                                            design={style === "cancel" ? 'soft' : 'grey'}
                                            onPress={style === "cancel" ? () => {
                                                onPress && onPress()
                                                onClose(alertIndex)
                                            } : onPress}
                                        >{text}</ButtonStyled>
                                        { isFirst && hasThree ? <View style={{ flex: 1 }} /> : null}
                                    </React.Fragment>
                                );
                            })}
                        </View>
                    ) : null}
                </View>
            </View>
        </Modal >
    );
}
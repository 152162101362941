const INITIAL_STATE = {
  todo: [],
  tovalidate: [],
};

export const feedbackReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_LIST_TO_DO':
      return { ...state, todo: action.data };
    case 'SET_LIST_TO_VALIDATE':
      return { ...state, tovalidate: action.data };
    case 'DELETE_TO_DO':
      var newTodo = state.todo.filter((item) => item.id !== action.data);
      return { ...state, todo: newTodo };
    case 'DELETE_TO_VALIDATE':
      var newTovalidate = state.tovalidate.filter(
        (item) => item.id !== action.data,
      );
      return { ...state, tovalidate: newTovalidate };
    case 'LOG_OUT':
      return INITIAL_STATE;
    default:
      return state;
  }
};

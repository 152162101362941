import { StyleSheet, Platform } from 'react-native';
import { colors } from '_theme';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: Platform.OS === 'web' ? '100vh' : '100%',
    margin: 0,
    padding: 0,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    backgroundColor: 'white',
    paddingTop: Platform.OS === 'ios' ? 70 : 40,
  },
  inputName: {
    marginLeft: 16,
    marginRight: 16,
    height: 50,
    borderColor: colors.grey_BF,
    borderRadius: 5,
    borderWidth: 1,
    color: colors.grey_66,
    padding: 16,
    backgroundColor: colors.grey_F9,
    fontFamily: 'VolteRounded-Semibold',
    fontSize: 12,
  },
  buttonConnect: {
    height: 50,
    width: '95%',
    backgroundColor: colors.pink_swood,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 100,
    alignSelf: 'center',
    marginTop: 40,
  },
  buttonText: {
    color: 'white',
    fontSize: 18,
    fontFamily: 'VolteRounded-Semibold',
  },
  inputTitleText: {
    fontFamily: 'VolteRounded-Semibold',
    fontSize: 14,
    color: colors.grey_66,
    marginLeft: 16,
    marginBottom: 8,
    marginTop: 25,
  },
  indication: {
    fontFamily: 'VolteRounded-Semibold',
    fontSize: 12,
    color: colors.green_swood,
    marginTop: 10,
    marginLeft: 16,
    marginRight: 16,
  },
});

import { StyleSheet, Platform } from 'react-native';
import { colors } from '_theme';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  },
  inputName: {
    marginLeft: 16,
    marginRight: 16,
    height: 50,
    borderColor: colors.grey_BF,
    borderRadius: 5,
    borderWidth: 1,
    color: colors.grey_66,
    padding: 16,
    backgroundColor: colors.grey_F9,
    fontFamily: 'VolteRounded-Semibold',
    fontSize: 12,
  },
  buttonConnect: {
    height: 50,
    width: '95%',
    backgroundColor: colors.pink_swood,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 100,
    alignSelf: 'center',
    marginTop: 40,
  },
  buttonText: {
    color: 'white',
    fontSize: 18,
    fontFamily: 'VolteRounded-Semibold',
  },
  inputTitleText: {
    fontFamily: 'VolteRounded-Semibold',
    fontSize: 14,
    color: colors.grey_66,
    marginLeft: 16,
    marginBottom: 8,
    marginTop: 25,
  },
  textTitle: {
    fontFamily: 'VolteRounded-Semibold',
    fontSize: 24,
    textAlign: 'center',
    color: colors.black_33,
    marginTop: 30,
    marginBottom: 30,
    marginRight: 15,
    marginLeft: 15,
  },
  textDesc: {
    fontFamily: 'VolteRounded-Semibold',
    fontSize: 14,
    color: colors.black_33,
    marginRight: 15,
    marginLeft: 15,
  },
});

import React from 'react';
import {
  StyleSheet,
  Platform,
  View,
  Text,
  TextInput,
  TouchableOpacity,
} from 'react-native';
import { useDispatch } from 'react-redux';
//
import { colors } from '_theme';
import { setUserAction } from '_redux/actions/userActions';
import { logInUser } from '_services/fetchManager';
//
import { BackgroundWave, SVGLocal } from '_components';
import Alert, { AlertRoot } from '_navigation/AlertCrossPlatform';

function LoginScreen({ navigation }) {
  const [email, onChangeEmail] = React.useState('');
  const [password, onChangePassword] = React.useState('');
  const dispatch = useDispatch();
  const onConnectPress = () => {
    logInUser({ email, password }).then((res) => {
      if (res?.success) {
        dispatch(setUserAction(res.data));
        navigation.navigate('Dashboard');
      } else {
        Alert.alert(
          'Authentification impossible',
          'Email ou mot de passe incorrect',
          [
            {
              text: 'OK',
              style: 'cancel',
            },
          ],
          { cancelable: false },
        );
      }
    });
  };

  const onPasswordPress = () => {
    navigation.navigate('Password');
  };

  const onCreatePress = () => {
    navigation.navigate('reset', { displayEmail: true });
  };

  const ButtonCreateAccount = () => {
    if (false) {
      return (
        <TouchableOpacity style={styles.buttonAccount} onPress={onCreatePress}>
          <Text style={styles.grayText}>Première connection ? </Text>
          <Text style={styles.greenText2}> Cliquez ici</Text>
        </TouchableOpacity>
      );
    }
    return null;
  };

  return (
    <BackgroundWave testID="login" innerStyle={styles.container}>
      <View style={styles.pictureLogo}>
        <SVGLocal name="boomerang_swood" />
      </View>
      <Text style={styles.titleText}>Bienvenue !</Text>
      <View style={styles.inputContainer}>
        <Text style={styles.inputTitleText}>Email</Text>
        <TextInput
          testID="inputEmail"
          style={styles.inputName}
          onChangeText={(text) => onChangeEmail(text)}
          value={email}
        />
        <Text style={styles.inputTitleText}>Mot de passe</Text>
        <TextInput
          testID="inputPassword"
          style={styles.inputName}
          onChangeText={(text) => onChangePassword(text)}
          value={password}
          secureTextEntry={true}
        />
        <TouchableOpacity
          testID="btnPassword"
          style={styles.buttonPassword}
          onPress={onPasswordPress}>
          <Text style={styles.greenText1}>Mot de passe oublié ?</Text>
        </TouchableOpacity>
      </View>
      <TouchableOpacity
        testID="btnConnect"
        style={styles.buttonConnect}
        onPress={onConnectPress}>
        <Text style={styles.buttonText}>SE CONNECTER</Text>
      </TouchableOpacity>
      <ButtonCreateAccount />
      <AlertRoot />
    </BackgroundWave>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
  },
  pictureLogo: {
    alignSelf: 'center',
    marginBottom: 30,
  },
  titleText: {
    fontFamily: 'VolteRounded-Semibold',
    fontSize: 24,
    color: colors.black_33,
    alignSelf: 'center',
    marginBottom: 5,
  },
  sectionInput: {
    flex: 1,
    justifyContent: 'center',
  },
  inputTitleText: {
    fontFamily: 'VolteRounded-Semibold',
    fontSize: 14,
    color: colors.grey_66,
    marginLeft: 16,
    marginBottom: 8,
    marginTop: 25,
  },
  inputName: {
    marginLeft: 16,
    marginRight: 16,
    height: 50,
    borderColor: colors.grey_BF,
    borderRadius: 5,
    borderWidth: 1,
    color: colors.grey_66,
    padding: 16,
    backgroundColor: colors.grey_F9,
    fontFamily: 'VolteRounded-Semibold',
    fontWeight: 'normal',
    fontSize: 12,
  },
  buttonConnect: {
    height: 50,
    width: '95%',
    backgroundColor: colors.pink_swood,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 100,
    alignSelf: 'center',
    marginTop: 40,
  },
  buttonText: {
    color: 'white',
    fontSize: 18,
    fontFamily: 'VolteRounded-Semibold',
  },
  buttonPassword: {
    marginTop: 8,
  },
  buttonAccount: {
    flexDirection: 'row',
    marginTop: 30,
    justifyContent: 'center',
  },
  greenText1: {
    color: colors.green_swood,
    fontSize: 10,
    fontFamily: 'VolteRounded-Semibold',
    alignSelf: 'flex-end',
    marginRight: 16,
  },
  greenText2: {
    color: colors.green_swood,
    fontSize: 10,
    fontFamily: 'VolteRounded-Semibold',
  },
  grayText: {
    color: colors.grey_66,
    fontSize: 10,
    fontFamily: 'VolteRounded-Semibold',
  },
  inputContainer: {
    alignSelf: 'center',
    width: '100%',
    maxWidth: 450,
  },
});

export default LoginScreen;

import React from 'react';
import {
  View,
  Image,
  TouchableOpacity,
  StyleSheet,
  Platform,
} from 'react-native';
import { useDispatch } from 'react-redux';
import { logOutUser } from '_redux/actions/userActions';
import { colors } from '_theme';
import { SVGLocal } from '_components/SVGLocal';
import exit from '_assets/pictures/exit_icon/exit.png';
import userIcon from '_assets/pictures/user_icon/user.png';

export const ListHeader = ({ navigation }) => {
  const dispatch = useDispatch();

  const onUserPress = () => {
    navigation.navigate('MonProfile');
  };

  return (
    <View style={styles.headerView}>
      <TouchableOpacity style={styles.roundButton} onPress={onUserPress}>
        <Image style={styles.imgIcon} source={userIcon} />
      </TouchableOpacity>
      <SVGLocal name="boomerang" />
    </View>
  );
}

export const styles = StyleSheet.create({
  headerView: {
    backgroundColor: 'white',
    height: Platform.OS === 'web' ? 100 : 130,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    shadowColor: colors.black_swood,
    shadowOffset: { width: 1, height: 6 },
    shadowOpacity: 0.5,
    shadowRadius: 8,
    elevation: 15,
    paddingTop: Platform.OS === 'web' ? 0 : 25,
  },
  roundButton: {
    position: 'absolute',
    top: Platform.OS === 'web' ? 30 : 60,
    left: 20,
    backgroundColor: colors.grey_light,
    width: 40,
    height: 40,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  imgIcon: {
    width: 20,
    height: 20,
  },
});

import { StyleSheet } from 'react-native';
import { colors, fonts, metrics } from '_theme';
let tooltipSize = 25
export const styles = StyleSheet.create({
    container: {
        width: tooltipSize * 1.5,
        height: tooltipSize,
        //marginVertical: -tooltipSize / 2,
        transform: [{ translateY: tooltipSize / 4 }]
    },
    icon: {
    },
});

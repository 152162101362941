import React from 'react';
import { StyleSheet, Text } from 'react-native';

import { fonts } from '_theme/index';

export const Title = ({ style, size = 'm', ...otherProps }) => {
    let textStyle = fonts?.fontStyles?.title[size]
    return (
        <Text
            style={[textStyle, style]}
            {...otherProps}
        />
    );
};
import React, { useEffect } from 'react';
import {
  View,
  Image,
  TextInput,
  TouchableOpacity,
  ScrollView,
} from 'react-native';
import { useSelector } from 'react-redux';
import { getUsers } from '_services/fetchManager';
import search from '_assets/pictures/search_icon/search.png';
import styles from './TeamScreenStyle';
import { ProfileHeader } from '_components';

function TeamScreen({ navigation }) {
  const user = useSelector((state) => state.user.user);
  const [members, onSetMembers] = React.useState([]);
  const [allMembers, onSetAllMembers] = React.useState([]);

  useEffect(() => {
    if (user === undefined) {
      navigation.navigate('Login');
    } else {
      getUsers({ token: user.token }).then((res) => {
        if (res.success === true) {
          onSetMembers(res.data);
          onSetAllMembers(res.data);
        }
      });
    }
  }, [navigation, user]);

  const onChangeText = (text) => {
    if (text === '') {
      onSetMembers(allMembers);
    } else {
      var list = allMembers.filter((item) =>
        item.name.toLowerCase().includes(text.toLowerCase()),
      );
      onSetMembers(list);
    }
  };

  const onMemberPress = (member) => {
    navigation.navigate('Swoody', { swoody: member });
  };

  return (
    <View style={styles.container}>
      <View style={styles.searchContainer}>
        <Image source={search} style={styles.searchImage} />
        <TextInput
          placeholder="Chercher"
          style={styles.searchInput}
          onChangeText={(text) => onChangeText(text)}
        />
      </View>
      <ScrollView>
        <MemberList members={members} onMemberPress={onMemberPress} />
      </ScrollView>
    </View>
  );
}

function MemberList({ members, onMemberPress }) {
  return (
    <View style={styles.teamContainer}>
      {(() => {
        const tab = [];
        for (let i = 0; i < members.length; i++) {
          tab.push(
            <TouchableOpacity
              style={styles.cellCard}
              key={i}
              onPress={() => onMemberPress(members[i])}>
              <ProfileHeader
                user={members[i]}
                mission={members[i].missions[0]}
                size={'small'}
              />
            </TouchableOpacity>,
          );
        }
        return tab;
      })()}
    </View>
  );
}

export default TeamScreen;

import { colors } from '_theme';
import { StyleSheet, Platform } from 'react-native';

export const styles = {
  container: {
    flex: 1,
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  },
  cellText: {
    color: colors.grey_66,
    fontSize: 14,
    fontFamily: 'VolteRounded-Semibold',
  },
  pictureLogo: {
    width: 280,
    height: 280,
  },
  actionsView: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  cellButton: {
    borderRadius: 8,
    height: 150,
    width: '45%',
    backgroundColor: 'white',
    marginTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: colors.black_swood,
    shadowOffset: { width: 1, height: 6 },
    shadowOpacity: 0.5,
    shadowRadius: 8,
    elevation: 10,
  },
  cellOpace: {
    backgroundColor: 'rgba(255,255,255, 0.7)',
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: 8,
  },
  cellButtonHighlight: {
    borderColor: colors.pink_swood,
    borderWidth: 1,
  },
  cellImageView: {
    width: 70,
    height: 70,
    //backgroundColor: 'white',
    borderRadius: 35,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cellImage: {
    width: 40,
    height: 40,
    resizeMode: 'cover',
  },
  cellTextHighlight: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cellPoint: {
    width: 15,
    height: 15,
    borderRadius: 8,
    backgroundColor: colors.pink_swood,
    margin: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  cellPointText: {
    fontFamily: 'VolteRounded-Semibold',
    color: 'white',
    fontSize: 12,
  },
};
